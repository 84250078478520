import { createContext, useContext, useState } from 'react';

const LogContext = createContext();

export function useLogContext() {
  return useContext(LogContext);
}

export function LogProvider({ children }) {
  const [logs, setLogs] = useState([]);

  // You can add any additional log-related functions or state here

  return (
    <LogContext.Provider value={{ logs, setLogs }}>
      {children}
    </LogContext.Provider>
  );
}

export default LogContext
