import axios from 'axios';
import { sortByAB } from './other';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getKeyStoragesApi() {
  const result = await getKeyStorages();
  if (result.status === 200) {
    return result;
  }
}

export async function getFilteredDataForExport(filters) {
  return await getFilteredKeyStorage(0, 0, filters);
}

export async function getFilteredKeyStorage(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/key-storage`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response.data.error;
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              if (filter.id !== 'search' && filter.value.length !== 0) {
                return product[filter.id] === parseInt(filter.value);
              }
              return (
                (product.id.toString() && product.id.toString().search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.key && product.key.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.catLicenseCatModule.name && product.catLicenseCatModule.name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.supplier.company_name && product.supplier.company_name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product?.invoice?.invoice_name && product.invoice.invoice_name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.key_origin && product.key_origin.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product?.invoice?.date_added && product.invoice.date_added.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.replacement.toString() && product.replacement.toString().search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.status.name && product.status.name.search(new RegExp(filter.value, 'i')) >= 0))
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'id':
                results = results.sort((a, b) =>
                  a.id < b.id ? -index : index
                );
                break;
              case 'key':
                results = results.sort((a, b) =>
                  a.key < b.key ? -index : index
                );
                break;
              case 'catLicenseCatModule':
                results = results.sort((a, b) =>
                  a.catLicenseCatModule.name < b.catLicenseCatModule.name ? -index : index
                );
                break;
              case 'supplier':
                results = results.sort((a, b) =>
                  a?.supplier?.company_name < b?.supplier?.company_name ? -index : index
                );
                break;
              case 'invoice':
                results = results.sort((a, b) =>
                  sortByAB(a?.invoice?.invoice_name, b?.invoice?.invoice_name, index, sort)
                );
                break;
              case 'key_origin':
                results = results.sort((a, b) =>
                  sortByAB(a.key_origin, b.key_origin, index, sort)
                );
                break;
              case 'date_added':
                results = results.sort((a, b) =>
                  sortByAB(a?.date_added, b?.date_added, index, sort)
                );
                break;
              case 'replacement':
                results = results.sort((a, b) =>
                  a.replacement < b.replacement ? -index : index
                );
                break;
              case 'status':
                results = results.sort((a, b) =>
                  a.status.name < b.status.name ? -index : index
                );
                break;
              default:
                break;
            }
          });
        return {
          data: results.slice(from, to > 0 ? to : totalResults),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createKeyStorage(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/key-storage`,
    withCredentials: true,
    data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateKeyStorageApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/key-storage/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteKeyStorageApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/key-storage/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getKeyStorages() {
  const config = {
    method: 'get',
    url: `${API_URL}/key-storage`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getKeyStorage(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/key-storage/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getKeyStorageByKeys(keys) {
  const config = {
    method: 'get',
    url: `${API_URL}/key-storage`,
    withCredentials: true,
    params: {
      keys: keys
    }
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function replaceKeyApi(id, data) {
  const config = {
    method: 'post',
    url: `${API_URL}/key-storage/replace/${id}`,
    withCredentials: true,
    data
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}