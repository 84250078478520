
import { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { getListApi as getListApiCategory } from '../../../../api/category';
import Modal from "react-modal";
import { getStatusesApi } from '../../../../api/status';
import { getKeyStoragesApi, replaceKeyApi } from '../../../../api/key-storage';
import UserContext from '../../../../utils/context/userContext';
import { roles } from '../../../../utils/data/role';
import { toast } from 'react-toastify';
import PNotify from '../../../../features/elements/p-notify';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '1000px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#app');

export default function KeyReplacementModal({ isOpen, onClose, details }) {
    const [arrayCategories, setArrayCategories] = useState([]);
    const [categoryName, setCategoryName] = useState(details?.catLicenseCatModule?.name ?? '');
    const [arrayStatuses, setArrayStatuses] = useState([]);
    const [statusName, setStatusName] = useState([]);
    const [arrayKeys, setArrayKeys] = useState([]);
    const [replacementKeyName, setReplacementKeyName] = useState('');
    const [invalidStatus, setInvalidStatus] = useState('');
    const loggedUser = useContext(UserContext);
    const isAdmin = loggedUser.level === roles.admin;

    const getStatuses = useCallback(async () => {
        let tableName = 'key_storage';
        const result = await getStatusesApi(tableName);
        if (result.status === 200) {
            setArrayStatuses(result.data.message);
            const invalidStatusObj = result.data.message.filter((element) => {
                return element.name === 'invalid-netrimis'
            })
            setInvalidStatus(invalidStatusObj[0].name)
        }
    },[])

    const getCategories = useCallback(async () => {
        const result = await getListApiCategory();
        if (result.status === 200) {
            setArrayCategories(result.data.message);
        }
    },[])

    const getKeys = useCallback(async () => {
        const result = await getKeyStoragesApi();
        if (result.status === 200) {
            let keys = result.data.message.filter(key => key.id !== details.id)
            keys = keys.filter(key => key.status.name !== invalidStatus)
            keys = keys.filter(key => key.catLicenseCatModule.name === categoryName)
            setArrayKeys(keys);
        }
    }, [details.id, categoryName])

    useEffect(() => {
        async function fetchData() {
            if(isAdmin){
                await Promise.all([getCategories(), getStatuses(), getKeys()]);
            } else {
                await Promise.all([getStatuses(), getKeys()])
            }
        }

        fetchData();

    }, [isAdmin, getKeys, getCategories, getStatuses])

    function closeModal(result = false) {
        onClose(result);
    }

    async function onSubmit(e) {
        e.preventDefault();

        const originalKeyId = details?.replacedKey?.original_key_storage_id ?? details.id;
        const replacementKey = arrayKeys.find(key => key.key === replacementKeyName);
        const data = {
            key_id: replacementKey?.id ?? null,
        }
        if (loggedUser.level === roles.admin) {
            const category = arrayCategories.find(category => category.name === e.target.category.value);
            const status = arrayStatuses.find(status => status.name === e.target.status.value);
            data.category_id = category?.id ?? '';
            data.status_id = status?.id ?? '';
        }
        const result = await replaceKeyApi(originalKeyId, data);
        closeModal(true);
        if (result.status === 200 && result.data.error === 0) {
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }

    }

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyles}
            onRequestClose={closeModal}
        >
            <Form
                className='core-form form'
                action='#'
                method='post'
                onSubmit={onSubmit}
                id='myForm'
            >
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <Row>
                                <Col lg={11} xl={11} className='d-flex align-items-center justify-content-center'>
                                    <b>Key:</b>&nbsp;{details.key}
                                </Col>
                                <Col lg={1} xl={1}>
                                    <Button
                                        className="modal-confirm px-3 py-2 rounded"
                                        variant="danger"
                                        onClick={() => closeModal(false)}
                                    >
                                        X
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col lg={12} xl={12}>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={3}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Categorie
                                    </Col>
                                    <Col lg={10} xl={7}>
                                        <input
                                            type='text'
                                            list='idCategories'
                                            name='category'
                                            id='inputIdCategories'
                                            placeholder="Categorie"
                                            className='w-100 form-control-modern form-control'
                                            value={categoryName ?? ''}
                                            onChange={(e) => setCategoryName(e.target.value)}
                                            required={!isAdmin}
                                            readOnly={!isAdmin}
                                            disabled={!isAdmin}
                                        />
                                        <datalist id='idCategories'>
                                            {/* show modules as options in input */}
                                            {typeof (arrayCategories) !== 'string' ? arrayCategories.map((item) => {
                                                return <option key={item.id} data-id={item.id} value={item.name} />;
                                            }) : ''}
                                        </datalist>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={3}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Chei
                                    </Col>
                                    <Col lg={10} xl={7}>
                                        <input
                                            type='text'
                                            list='idKeys'
                                            name='key'
                                            id='inputIdKeys'
                                            placeholder="Cheie"
                                            className='w-100 form-control-modern form-control'
                                            onChange={(e) => setReplacementKeyName(e.target.value)}
                                            required={!isAdmin}
                                        />
                                        <datalist id='idKeys'>
                                            {/* show modules as options in input */}
                                            {typeof (arrayKeys) !== 'string' ? arrayKeys.map((item) => {
                                                return <option key={item.id} value={item.key} />;
                                            }) : ''}
                                        </datalist>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className='align-items-center'>
                                    <Col
                                        as={Form.Label}
                                        lg={3}
                                        xl={2}
                                        className='control-label text-lg-right mb-lg-0'
                                    >
                                        Status
                                    </Col>
                                    <Col lg={10} xl={7}>
                                        <input
                                            type='text'
                                            list='idStatus'
                                            name='status'
                                            id='inputIdStatus'
                                            placeholder="Status"
                                            className='w-100 form-control-modern form-control'
                                            defaultValue={invalidStatus ?? ''}
                                            onChange={(e) => setStatusName(e.target.value)}
                                            required={!isAdmin}
                                            readOnly={!isAdmin}
                                            disabled={!isAdmin}
                                        />
                                        <datalist id='idStatus'>
                                            {/* show modules as options in input */}
                                            {typeof (arrayStatuses) !== 'string' ? arrayStatuses.map((item) => {
                                                return <option key={item.id} value={item.name} />;
                                            }) : ''}
                                        </datalist>
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col md={12} className="text-right">
                                <Button
                                    className="modal-confirm mr-2"
                                    variant="secondary"
                                    onClick={() => closeModal(false)}
                                >
                                    Close
                                </Button>
                                <Button
                                    className="modal-confirm mr-2"
                                    variant="primary"
                                    disabled={!replacementKeyName && !isAdmin}
                                    type='submit'
                                >
                                    Replace
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Form>
        </Modal>
    )
}
