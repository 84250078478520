import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';
import { getListApi as getListApiCategory } from '../../../api/category';

import { deleteApi, updateApi, getApiById } from '../../../api/catlicences-catmodules';
import { getListApi } from '../../../api/product-code';


export default function CatLicencesCatModulesDetail() {
  const [item, setItem] = useState(null);
  const [itemOld, setItemOld] = useState(null);
  let [arrayModules, setArrayModules] = useState([]);
  let [arrayCategories, setArrayCategories] = useState([]);

  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getApiById(id).then((result) => {
      setItem(result.data.message);
      setItemOld(result.data.message);
      setLoading(false);
    });
    async function getProductCode() {
      const result = await getListApi();
      if(result.status === 200) {  
        setArrayModules(result.data.message);
      }
    }
    async function getCategories() {
      const result = await getListApiCategory();
      if(result.status === 200) {  
        setArrayCategories(result.data.message);
      }
    }
    if (arrayModules.length === 0) {
      getProductCode();
    }
    if (arrayCategories.length === 0) {
      getCategories();
    }
    document.querySelector('body').classList.add('loaded');
  }, [id, setItem, setItemOld, arrayCategories, arrayModules]);

  async function updateItem(e) {
    e.preventDefault();

    let data = {
      categoryName: e.target.idCategoryName.value,
      productModelName: e.target.idModuleName.value
    };

    const result = await updateApi(data, id);
    if (result.status === 200 && result.data.error === 0) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/conexiuni`);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  function deleteItem(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  async function deleteConfirm(result) {
    setOpenModal(false);
    if (result) {
      navigate(`${process.env.PUBLIC_URL}/dashboard/conexiuni`);

      const checker = await deleteApi(id);
      if (checker.status === 200 && checker.data.error === 0) {
        navigate(`${process.env.PUBLIC_URL}/dashboard/cod-produs`);
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={checker.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current='Editeză Conexiune'
            paths={[
              {
                name: 'Dashboard',
                url: '/dashboard',
              },
              {
                name: 'Panou pentru întreținere CEL',
                url: '#',
              },
              {
                name: 'Conexiune',
                url: '#',
              },
              {
                name: 'Editeză Conexiune',
                url: '#',
              },
            ]}
          />

          <Form
            className='ecommerce-form'
            action='#'
            method='post'
            onSubmit={updateItem}
          >
            <Row>
              <Col>
                <Card className='card-modern card-big-info'>
                  <Card.Body>
                    <Row>
                      <Col lg='2-5' xl='1-5'>
                        <i className='card-big-info-icon bx bx-user-circle'></i>
                        <h2 className='card-big-info-title'>Informații conexiune</h2>
                        <p className='card-big-info-desc'>
                          Adaugă aici informații pentru conexiune.
                        </p>
                      </Col>
                      <Col lg='3-5' xl='4-5'>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Conexiune
                          </Col>
                          <Col lg={7} xl={6}>
                            <input
                              type='text'
                              list='idModule'
                              name='idModuleName'
                              id='inputIdModule'
                              placeholder="Conexiune"
                              className='w-100 form-control-modern form-control'
                              defaultValue={arrayModules.find((module) => module.id === itemOld.id_product_model)?.keys_model}
                              required
                            />
                            <datalist id='idModule'>
                              {/* show modules as options in input */}
                              {arrayModules.map((item) => {
                                return <option key={item.id} value={item.keys_model} />;
                              })}
                            </datalist>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className='align-items-center'>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Categorie
                          </Col>
                          <Col lg={7} xl={6}>
                            <input
                              type='text'
                              list='idCategory'
                              name='idCategoryName'
                              id='inputIdModule'
                              placeholder="Categorie"
                              className='w-100 form-control-modern form-control'
                              defaultValue={arrayCategories.find((category) => category.id === itemOld.id_category_licenses)?.name}
                              required
                            />
                            <datalist id='idCategory'>
                              {/* show modules as options in input */}
                              {arrayCategories.map((item) => {
                                return <option key={item.id} value={item.name} />;
                              })}
                            </datalist>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='action-buttons'>
              <Col md='auto' className='col-6'>
                <Button
                  type='submit'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='primary'
                >
                  <i className='bx bx-save text-4 mr-2'></i> Salvează
                </Button>
              </Col>
              <Col md='auto' className='col-6 px-md-0 mt-0'>
                <Button
                  onClick={() => navigate(-1)}
                  className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                  variant='light'
                >
                  Înapoi
                </Button>
              </Col>
              <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                <Button
                  href='#delete'
                  className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                  variant='danger'
                  onClick={deleteItem}
                >
                  <i className='bx bx-trash text-4 mr-2'></i> Șterge
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
}
