import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';
// Get status from api
export async function getStatus() {
  const config = {
    method: 'get',
    url: `${API_URL}/generate/getStatus`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// Start generator from api
export async function startGenerator() {
  const config = {
    method: 'get',
    url: `${API_URL}/generate/start`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// Stop generator from api
export async function stopGenerator() {
  const config = {
    method: 'get',
    url: `${API_URL}/generate/stop`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// TODO remove this when frontend view is implemented
export async function retryJobs() {
  const config = {
    method: 'post',
    url: `${API_URL}/activation/failed-jobs/retry-failed-jobs`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export async function getOrder(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/generate/get-order/${id}`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function resendOrderEmail(data) {
  const config = {
    method: 'POST',
    url: `${API_URL}/generate/resend-order-email`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function sendReplacementKeys(data) {
  const config = {
    method: 'POST',
    url: `${API_URL}/generate/send-replacement-keys`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}