import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { createApi } from '../../../api/keys';
import { getListApi as getListApiCategory } from '../../../api/category';
import { useState } from 'react';


export default function KeysCreate() {
  let [arrayCategories, setArrayCategories] = useState([]);
  let [fileText, setFileText] = useState('select-file');
  let [arrayFileTxt, setArrayFileTxt] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {

    async function getCategories() {
      const result = await getListApiCategory();
      if(result.status === 200) {  
        setArrayCategories(result.data.message);
      }
    }

    if (arrayCategories.length === 0) {
      getCategories();
    }

    document.querySelector('body').classList.add('loaded');
  }, [arrayCategories]);

  async function save(event) {
    event.preventDefault();


    let data = {
      keys: arrayFileTxt,
      catLicenses: event.target.idCategories.value
    };
    const result = await createApi(data);
    if (result.status === 200 && result.data.error === 0) {
      document.getElementById('myForm').reset();
      setArrayFileTxt([]);
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      setArrayFileTxt([]);
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

    // event input on change
    const onChangeTxt = async (e) => {
      // reset used states
      setFileText('select-file');
      setArrayFileTxt([]);
      // get file from event
      const files = e.target.files[0];
      // create render file
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result.toString();
        const array = text.split('\n');
        let i = 0;
        // read file foreach line
        array.forEach((element) => {
          if (element === '' || !(element.length > 15) || element === '\r') {
            array.splice(i);
          }
          i++;
        });
        // set array of keys
        setArrayFileTxt(array);
      };
      reader.readAsText(files);
      setFileText(files.name);
      e.target.value = '';
    };
  
  return (
    <>
      <Breadcrumb
        current='Adaugă cheie de licență'
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Panou pentru întreținere CEL',
            url: '#',
          },
          {
            name: 'Cheile de licență',
            url: '#',
          },
          {
            name: 'Adaugă cheie de licență',
            url: '/dashboard/cheie/create',
          },
        ]}
      />

      <Form
        className='core-form form'
        action='#'
        method='post'
        onSubmit={save}
        id='myForm'
      >
        <Row>
          <Col>
            <Card className='card-modern card-big-info'>
              <Card.Body>
                <Row>
                  <Col lg='2-5' xl='1-5'>
                    <i className='card-big-info-icon bx bxs-user-badge'></i>
                    <h2 className='card-big-info-title'>Informații chei de licență</h2>
                    <p className='card-big-info-desc'>
                      Adaugă aici informații pentru cheile de licență.
                    </p>
                  </Col>
                  <Col lg='3-5' xl='4-5'>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Categorie
                      </Col>
                      <Col lg={7} xl={6}>
                        <input
                          type='text'
                          list='idCategories'
                          name='idCategories'
                          id='inputIdCategories'
                          placeholder="Categorie"
                          className='w-100 form-control-modern form-control'
                          required
                        />
                        <datalist id='idCategories'>
                        {/* show modules as options in input */}
                          {arrayCategories.map((item) => {
                            return <option key={item.id} value={item.name} />;
                          })}
                        </datalist>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId='uploadTxt align-items-center'>
                     <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Încarcă fișierul txt
                      </Col>
                      <Col lg={7} xl={6}>

                      <Form.File id='formcheck-api-custom' custom>
                        <Form.File.Input
                        className='form-control-modern form-control'
                          type='file'
                          multiple
                          accept='.txt'
                          onChange={onChangeTxt}
                        />
                        <Form.File.Label>
                          Selecteză un fișier
                        </Form.File.Label>
                      </Form.File>
                      </Col>
                    </Form.Group>
                    {/* Show readed keys from file */}
                    {arrayFileTxt.length > 0 && (
                      <div>
                        <p>Cheile citite {arrayFileTxt.length}</p>
                        <Col style={{flex: "1 1", height: "280px", overflowY: "scroll", display: "flex", justifyContent: "left", background: "#e7e7e7",}}>
                            <ul style={{marginLeft: '0', paddingInline: '10px', paddingInlineEnd: '10px'}}>
                              {arrayFileTxt.map((item, key) => {
                                return <li key={key}>{item}</li>;
                              })}
                            </ul>
                        </Col>
                      </div>
                    )}
                  </Col>
                  <Form.Row>
              </Form.Row>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='action-buttons'>
          <Col md='auto' className='col-12'>
            <Button
              type='submit'
              className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
              variant='primary'
            >
              <i className='bx bx-save text-4 mr-2'></i>Salvează
            </Button>
          </Col>
          <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
            <Button
              onClick={() => navigate(-1)}
              className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
              variant='light'
            >
              Înapoi
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
