import axios from 'axios';
const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getLoggedUser() {
  const config = {
    method: 'get',
    url: `${API_URL}/login/getLoggedUser`,
    withCredentials: true,
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export async function login(data) {
  const config = {
    method: 'POST',
    url: `${API_URL}/login`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: data,
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function sendResetToken(email) {
  const config = {
    method: 'POST',
    url: `${API_URL}/login/password-reset`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: { email },
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    })
}

export async function resetPassword(data) {
  const config = {
    method: 'POST',
    url: `${API_URL}/login/reset-password`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    })
}

export async function logout() {
  const config = {
    method: 'patch',
    url: `${API_URL}/login`,
    withCredentials: true
  };
  return await axios(config)
    .then((result) => {
      sessionStorage.removeItem('jwt');
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function check() {
  const config = {
    method: 'get',
    url: `${API_URL}/login/check`,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return await axios(config)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error.response;
    });
}