import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { createApi } from '../../../api/catlicences-catmodules';
import { getListApi } from '../../../api/product-code';
import { getListApi as getListApiCategory } from '../../../api/category';
import { useState } from 'react';


export default function CatLicencesCatModulesCreate() {
  let [arrayModules, setArrayModules] = useState([]);
  let [arrayCategories, setArrayCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getProductCode() {
      const result = await getListApi();
      if(result.status === 200) {  
        setArrayModules(result.data.message);
      }
    }

    async function getCategories() {
      const result = await getListApiCategory();
      if(result.status === 200) {  
        setArrayCategories(result.data.message);
      }
    }

    if (arrayModules.length === 0) {
      getProductCode();
    }

    if (arrayCategories.length === 0) {
      getCategories();
    }

    document.querySelector('body').classList.add('loaded');
  }, [arrayModules, arrayCategories]);

  async function save(event) {
    event.preventDefault();


    let data = {
      nameModule: event.target.idModule.value,
      nameCategory: event.target.idCategories.value
    };
    const result = await createApi(data);
    if (result.status === 200 && result.data.error === 0) {
      document.getElementById('myForm').reset();
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }
  
  return (
    <>
      <Breadcrumb
        current='Adaugă conexiune'
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Panou pentru întreținere CEL',
            url: '#',
          },
          {
            name: 'Conexiune între cod și categorie',
            url: '#',
          },
          {
            name: 'Adaugă conexiune',
            url: '/dashboard/conexiuni/create',
          },
        ]}
      />

      <Form
        className='core-form form'
        action='#'
        method='post'
        onSubmit={save}
        id='myForm'
      >
        <Row>
          <Col>
            <Card className='card-modern card-big-info'>
              <Card.Body>
                <Row>
                  <Col lg='2-5' xl='1-5'>
                    <i className='card-big-info-icon bx bxs-user-badge'></i>
                    <h2 className='card-big-info-title'>Informații conexiune</h2>
                    <p className='card-big-info-desc'>
                      Adaugă aici informații pentru conexiune.
                    </p>
                  </Col>
                  <Col lg='3-5' xl='4-5'>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Conexiune
                      </Col>
                      <Col lg={7} xl={6}>
                        <input
                          type='text'
                          list='idModule'
                          name='idModule'
                          id='inputIdModule'
                          placeholder="Conexiune"
                          className='w-100 form-control-modern form-control'
                        />
                        <datalist id='idModule'>
                        {/* show modules as options in input */}
                          {arrayModules.map((item) => {
                            return <option key={item.id} value={item.keys_model} />;
                          })}
                        </datalist>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Categorie
                      </Col>
                      <Col lg={7} xl={6}>
                        <input
                          type='text'
                          list='idCategories'
                          name='idCategories'
                          id='inputIdCategories'
                          placeholder="Categorie"
                          className='w-100 form-control-modern form-control'
                        />
                        <datalist id='idCategories'>
                        {/* show modules as options in input */}
                          {arrayCategories.map((item) => {
                            return <option key={item.id} value={item.name} />;
                          })}
                        </datalist>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='action-buttons'>
          <Col md='auto' className='col-12'>
            <Button
              type='submit'
              className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
              variant='primary'
            >
              <i className='bx bx-save text-4 mr-2'></i>Salvează
            </Button>
          </Col>
          <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
            <Button
              onClick={() => navigate(-1)}
              className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
              variant='light'
            >
              Înapoi
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
