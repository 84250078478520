import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';
import { Link } from 'react-router-dom';
import { deleteActivationAccountEntries, getActivationAccounts, updateActivationAccount } from '../../../api/activation-accounts';
import ActivationAccountActivationsModal from './modals/account-activations-modal';

export default function ActivationAccountsList() {
    const [loading, setLoading] = useState(true);
    const [activivationAccountModal, setActivationAccountModal] = useState({
        isOpen: false,
        currentAccount: null,
    });
    const [ajax, setAjax] = useState({
        data: [],
        total: 0,
    });
    const [search, setSearch] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [tableRef, setTableRef] = useState(null);
    const [selected, setSelected] = useState([]);
    const [bulk, setBulk] = useState('');

    const columns = [
        {
            Header: 'Numar comanda',
            accessor: 'orderId',
            sortable: true,
        },
        {
            Header: '# Activari',
            accessor: 'numberOfActivations',
            sortable: true,
        },
        {
            Header: 'Actions',
            accessor: 'id',
            className: 'actions',
            headerClassName: 'justify-content-center',
            width: 100,
            Cell: (row) => (
                <>
                    <a
                        href='#del'
                        className='on-default remove-row'
                        onClick={(e) => setActivationAccountModal(prevState => ({ ...prevState, isOpen: true, currentAccount: ajax.data.find(account => account.id === row.value) }))}
                    >
                        <i className='fas fa-pencil-alt'></i>
                    </a>
                    <a
                        href='#edit'
                        className='on-default remove-row'
                        onClick={(e) => deleteRow(e, row.value)}
                    >
                        <i className='far fa-trash-alt'></i>
                    </a>
                </>
            ),
        },
    ];

    useEffect(() => {
        setSelected((selected) =>
            selected.map((item) => {
                return {
                    ...item,
                    selected: selectAll,
                };
            })
        );
        document.querySelector('body').classList.add('loaded');
    }, [selectAll]);

    function isSelected(key) {
        return selected.find((item) => item.id === key && item.selected);
    }

    function onSelectChange(e, value, row) {
        setSelected(
            selected.map((item) => {
                if (item.id === row.id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            })
        );
    }

    async function bulkAction(e) {
        e.preventDefault();
        if (!bulk) {
            return toast(
                <PNotify
                    title='Warning'
                    icon='fas fa-exclamation'
                    text='Please choose one of actions.'
                />,
                {
                    containerId: 'default',
                    className: 'notification-warning',
                }
            );
        }
    }

    async function deleteRow(e, index) {
        e.preventDefault();
        if (window.confirm('Ești sigur că vrei să ștergi aceste date?')) {

            const deleteItem = await deleteActivationAccountEntries(index);
            if (deleteItem.status === 200) {
                setAjax({
                    ...ajax,
                    data: ajax.data.filter((item) => item.id !== index),
                    totalRecords: ajax.totalRecords - 1,
                    totalFilteredRecords: ajax.totalFilteredRecords - 1
                });
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={deleteItem.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={deleteItem.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    function fetchData(state) {
        let filtered = [...state.filtered];
        setLoading(true);
        getActivationAccounts(
            state.page + 1,
            state.pageSize,
            filtered,
            state.sorted
        ).then((results) => {
            if (results?.data) {
                setAjax({
                    data: results.data,
                    total:
                        parseInt(results.total / state.pageSize) +
                        !!(results.total % state.pageSize),
                    totalRecords: results.total,
                    isFiltered: filtered.length ? true : false,
                });
                setSelected(
                    results.data.map((media) => {
                        return {
                            id: media.id,
                            selected: false,
                        };
                    })
                );
                setSelectAll(false);
            }
            setLoading(false);
        });

    }

    function searchActivationAccounts(e) {
        e.preventDefault();
        tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
    }

    async function handleUpdate(value) {
        setActivationAccountModal({
            isOpenInvoiceAssociationModal: false,
            currentInvoice: null
        })
        if (value) {
            const result = await updateActivationAccount(value.id, value);
            if (result.status === 200 && result.data.error === 0) {
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={result.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={result.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    return (
        <>
            <Breadcrumb
                current={'Conturi Activare CEL'}
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'PANOU PENTRU ÎNTREȚINERE CEL',
                        url: '#',
                    },
                    {
                        name: 'Conturi Activare CEL',
                        url: '/dashboard/activation-accounts',
                    },
                ]}
            />

            <Row>
                <Col>
                    <Form method='GET' action='#' onSubmit={searchActivationAccounts}>
                        <Card className='card-modern'>
                            <Card.Body>
                                <div className='datatables-header-footer-wrapper'>
                                    <div className='datatable-header'>
                                        <Row className='align-items-lg-center mb-3'>
                                            <Col className='col-auto pl-lg-1 ml-auto'>
                                                <div className='search search-style-1 mx-lg-auto w-auto'>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type='text'
                                                            className='search-term'
                                                            placeholder='Search'
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                        />
                                                        <InputGroup.Append>
                                                            <Button variant='default' type='submit'>
                                                                <i className='bx bx-search'></i>
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <PtTable
                                        className='table table-core-simple -striped mb-0'
                                        data={ajax.data}
                                        loading={loading}
                                        columns={columns}
                                        pages={ajax.total}
                                        pageSize={12}
                                        manual
                                        onFetchData={fetchData}
                                        selectAll={selectAll}
                                        toggleAll={() => setSelectAll(!selectAll)}
                                        isSelected={(key) => isSelected(key)}
                                        toggleSelection={onSelectChange}
                                        onChangeRef={(ref) => setTableRef(ref)}
                                        totalRecords={ajax.totalRecords}
                                        totalFilteredRecords={ajax.totalFilteredRecords}
                                        isFiltered={ajax.isFiltered}
                                    />

                                    <div className='datatable-footer'>
                                        <Row className='align-items-center justify-content-between mt-3'>
                                            <Col md='auto' className='mb-3 mb-lg-0'>
                                                <div className='d-flex'>
                                                    <Form.Control
                                                        as='select'
                                                        className='select-style-1 bulk-action w-auto mr-3'
                                                        value={bulk}
                                                        onChange={(e) => setBulk(e.target.value)}
                                                        style={{ minWidth: '120px' }}
                                                    >
                                                        <option value=''>Bulk Actions</option>
                                                    </Form.Control>
                                                    <Button
                                                        href='#bulk-action'
                                                        className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                                                        variant='light'
                                                        onClick={bulkAction}
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg='auto' className='mb-3 mb-lg-0'>
                                                <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>
            {activivationAccountModal.isOpen && (
                <ActivationAccountActivationsModal
                    isOpen={activivationAccountModal.isOpen}
                    onClose={handleUpdate}
                    currentAccount={activivationAccountModal.currentAccount}
                />
            )
            }
        </>
    );

}