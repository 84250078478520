import { Card, Col, Row, Form, Button } from "react-bootstrap";
import KeyReplacementModal from "./modals/key-replacement-modal";
import { useState } from "react";
import CopyToClipBoardBtn from "../../../features/elements/copy-to-clipboard-btn";
import { formatDateTime } from "../../../utils";

export default function KeyStorageInfo({ searchedKey }) {
    const [isOpenReplacementModal, setIsOpenReplacementModal] = useState(false);

    function openReplacementModal() {
        setIsOpenReplacementModal(true);
    }

    function handleOnClose() {
        setIsOpenReplacementModal(false)
    }

    return (
        <>
            <Row>
                <Col>
                    <Card.Header>
                        <Card.Title>
                            Key Info
                        </Card.Title>
                    </Card.Header>
                    <Card className="card-modern">
                        <Card.Body>
                            <Row>
                                <Col lg={10} xl={10}>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={3}
                                            className='control-label text-lg-left mb-lg-0'
                                        >
                                            Key :
                                        </Col>
                                        <Col lg={8} xl={8}>
                                            <input
                                                type='text'
                                                name='idKey'
                                                id={`inputIdKey_${searchedKey.id}`}
                                                placeholder="Key"
                                                className='w-100 form-control-modern form-control'
                                                value={searchedKey?.key ?? ''}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <CopyToClipBoardBtn
                                            inputId={`inputIdKey_${searchedKey.id}`}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={3}
                                            className='control-label text-lg-left mb-lg-0'
                                        >
                                            Category :
                                        </Col>
                                        <Col lg={8} xl={8}>
                                            <input
                                                type='text'
                                                name='idCategory'
                                                id={`inputIdCategory_${searchedKey.id}`}
                                                placeholder="Key"
                                                className='w-100 form-control-modern form-control'
                                                value={searchedKey?.catLicenseCatModule.name ?? ''}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <CopyToClipBoardBtn
                                            inputId={`inputIdCategory_${searchedKey.id}`}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={3}
                                            className='control-label text-lg-left mb-lg-0'
                                        >
                                            Invoice :
                                        </Col>
                                        <Col lg={8} xl={8}>
                                            <input
                                                type='text'
                                                name='idInvoice'
                                                id={`inputIdInvoice_${searchedKey.id}`}
                                                placeholder="Invoice"
                                                className='w-100 form-control-modern form-control'
                                                value={searchedKey?.invoice?.invoice_name ?? '-'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <CopyToClipBoardBtn
                                            inputId={`inputIdInvoice_${searchedKey.id}`}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={3}
                                            className='control-label text-lg-left mb-lg-0'
                                        >
                                            Replacement Data:
                                        </Col>
                                        <Col lg={8} xl={8}>
                                            <input
                                                type='text'
                                                name='idDateAdded'
                                                id={`inputIdDateAdded_${searchedKey.id}`}
                                                placeholder="Replacement Data"
                                                className='w-100 form-control-modern form-control'
                                                value={searchedKey?.date_added ?? '-'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <CopyToClipBoardBtn
                                            inputId={`inputIdDateAdded_${searchedKey.id}`}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={3}
                                            className='control-label text-lg-left mb-lg-0'
                                        >
                                            Supplier :
                                        </Col>
                                        <Col lg={8} xl={8}>
                                            <input
                                                type='text'
                                                name='idSupplier'
                                                id={`inputIdSupplier_${searchedKey.id}`}
                                                placeholder="Key"
                                                className='w-100 form-control-modern form-control'
                                                value={searchedKey?.supplier.company_name ?? ''}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <CopyToClipBoardBtn
                                            inputId={`inputIdSupplier_${searchedKey.id}`}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={3}
                                            className='control-label text-lg-left mb-lg-0'
                                        >
                                            Status :
                                        </Col>
                                        <Col lg={8} xl={8}>
                                            <input
                                                type='text'
                                                name='idStatus'
                                                id={`inputIdStatus_${searchedKey.id}`}
                                                placeholder="Key"
                                                className='w-100 form-control-modern form-control'
                                                value={searchedKey?.status.name ?? ''}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <CopyToClipBoardBtn
                                            inputId={`inputIdStatus_${searchedKey.id}`}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {(searchedKey?.replacementHistory && searchedKey?.replacementHistory.length) || searchedKey?.replacedKey ? (
                        <>
                            <Row>
                                <Col>
                                    <Card.Header>
                                        <Card.Title>
                                            {searchedKey?.replacementHistory?.length ? 'Replacement History' : 'Currently Replaced Key'}
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        {searchedKey.replacementHistory.length ?
                                            searchedKey.replacementHistory.map((replacement, index) => {
                                                return (
                                                    <>
                                                        <Form.Group as={Row} className='align-items-center'>
                                                            <Col
                                                                as={Form.Label}
                                                                lg={3}
                                                                xl={3}
                                                                className='control-label text-lg-center mb-lg-0'
                                                            >
                                                                Replacement {++index}:
                                                            </Col>
                                                            <Col lg={8} xl={8} className="d-flex flex-row">
                                                                <Col lg={10} xl={10}>
                                                                    <input
                                                                        type='text'
                                                                        name='idSuppliers'
                                                                        id={`inputIdSuppliers_${replacement.originalKeyStorage.id}`}
                                                                        placeholder="Key"
                                                                        className='w-100 form-control-modern form-control'
                                                                        value={replacement?.originalKeyStorage.key ?? ''}
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </Col>
                                                                <CopyToClipBoardBtn
                                                                    inputId={`inputIdSuppliers_${replacement.originalKeyStorage.id}`}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className='align-items-center border-0 pt-0 pl-3'>
                                                            <Col lg={9} xl={9} className="d-flex">
                                                                <Col
                                                                    as={Form.Label}
                                                                    lg={9}
                                                                    xl={9}
                                                                    className='control-label text-lg-right mb-lg-0 d-flex align-items-center justify-content-end pr-0'
                                                                >
                                                                    Replaced at :
                                                                </Col>
                                                                <Col lg={4} xl={4} className="pl-1">
                                                                    <input
                                                                        type='text'
                                                                        name='idSuppliers'
                                                                        id={`inputIdReplacedtAt_${replacement.originalKeyStorage.id}`}
                                                                        placeholder="Key"
                                                                        className='w-100 form-control-modern form-control text-center'
                                                                        value={formatDateTime(new Date(replacement?.created_at)) ?? ''} // formatting with mm/dd/yyyy makes it print as dd/mm/yyyy for some reason
                                                                        readOnly
                                                                        disabled
                                                                    />
                                                                </Col>
                                                                <CopyToClipBoardBtn
                                                                    inputId={`inputIdReplacedtAt_${replacement.originalKeyStorage.id}`}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }) : (
                                                <>
                                                    <Form.Group as={Row} className='align-items-center'>
                                                        <Col
                                                            as={Form.Label}
                                                            lg={3}
                                                            xl={3}
                                                            className='control-label text-lg-center mb-lg-0'
                                                        >
                                                            Replacing :
                                                        </Col>
                                                        <Col lg={8} xl={8} className="d-flex">
                                                            <Col lg={10} xl={10}>
                                                                <input
                                                                    type='text'
                                                                    name={`idReplacement_${searchedKey?.replacedKey.replacementKeyStorage.id}`}
                                                                    id={`inputIdReplacement_${searchedKey?.replacedKey.replacementKeyStorage.id}`}
                                                                    placeholder="Key"
                                                                    className='w-100 form-control-modern form-control'
                                                                    value={searchedKey?.replacedKey.replacementKeyStorage.key ?? ''}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <CopyToClipBoardBtn
                                                                inputId={`inputIdReplacement_${searchedKey?.replacedKey.replacementKeyStorage.id}`}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className='align-items-center border-0 pt-0 pl-3'>
                                                        <Col lg={9} xl={9} className="d-flex">
                                                            <Col
                                                                as={Form.Label}
                                                                lg={9}
                                                                xl={9}
                                                                className='control-label text-lg-right mb-lg-0 d-flex align-items-center justify-content-end pr-0'
                                                            >
                                                                Replaced at :
                                                            </Col>
                                                            <Col lg={4} xl={4} className="pl-1">
                                                                <input
                                                                    type='text'
                                                                    name='idSuppliers'
                                                                    id={`inputIdReplacementAt_${searchedKey?.replacedKey.replacementKeyStorage.id}`}
                                                                    placeholder="Key"
                                                                    className='w-100 form-control-modern form-control text-center'
                                                                    value={formatDateTime(new Date(searchedKey?.replacedKey?.created_at)) ?? ''} // formatting with mm/dd/yyyy makes it print as dd/mm/yyyy for some reason
                                                                    readOnly
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <CopyToClipBoardBtn
                                                                inputId={`inputIdReplacementAt_${searchedKey?.replacedKey.replacementKeyStorage.id}`}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </>
                                            )
                                        }
                                    </Card.Body>
                                </Col>
                            </Row>
                        </>
                    ) : ('')}
                </Col>
            </Row>
            <Row className="pt-0 w-auto">
                <Col>
                    <Card.Body className="pt-1">
                        <hr />
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                type='button'
                                onClick={(e) => openReplacementModal()}
                            >
                                Replace
                            </Button>
                        </Col>
                    </Card.Body>
                </Col>
            </Row>
            {searchedKey && isOpenReplacementModal ? (
                <>
                    <KeyReplacementModal
                        isOpen={isOpenReplacementModal}
                        details={searchedKey}
                        onClose={handleOnClose}
                    />
                </>
            ) : ('')}
            <hr />
        </>
    )
}