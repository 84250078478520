import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import Loader from "../../../features/loader";
import { getAvgCid, getLandingSettings, updateLandingSettings } from "../../../api/settings-landing";
import { toast } from "react-toastify";
import PNotify from "../../../features/elements/p-notify";
import { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import "../../css/index.css"

export default function LandingSettings() {
  const [loading, setLoading] = useState(true);
  const [avgCidPrice, setAvgCidPrice] = useState(null);

  const [formData, setFormData] = useState({
    popup_activare_microsoft_maintainance: {
      isActive: 0,
      title: "",
      content: "",
    },
    isActivareEnabled: 1,
    pricePerCid: 0,
    old_password: "",
    password: ""
  });

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getLandingSettings().then((result) => {
      const settingsObject = result.data.message.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {});
      const popupValues = JSON.parse(settingsObject.popup_activare_microsoft_maintainance);
      setFormData(prevData => ({
        ...prevData,
        pricePerCid: settingsObject.pricePerCid,
        isActivareEnabled: settingsObject.isActivareEnabled,
        popup_activare_microsoft_maintainance: {
          isActive: popupValues.isActive,
          title: popupValues.title,
          content: popupValues.content,
        }
      }));
      setLoading(false);
    });

    if (!avgCidPrice) {
      getAvgCid().then((result) => {
        if (result && result.status === 200) {
          setAvgCidPrice(parseFloat(result.data.message).toFixed(2));
        }
      })
    }

    document.querySelector("body").classList.add("loaded");
  }, []);

  async function updateSettingsLanding(e) {
    e.preventDefault();
    const result = await updateLandingSettings(formData);
    if (result.status === 200 && result.data.error === 0) {
      setFormData(prevData => ({
        ...prevData,
        old_password: "",
        password: ""
      }));
      return toast(
        <PNotify
          title="Success"
          icon="fas fa-check"
          text={result.data.message}
        />,
        {
          containerId: "default",
          className: "notification-success",
        }
      );
    } else {
      return toast(
        <PNotify
          title="Error"
          icon="fas fa-check"
          text={result.data.message}
        />,
        {
          containerId: "default",
          className: "notification-danger",
        }
      );
    }
  }

  function handleInputChange(e) {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));
  }


  const onChangeMarkdown = useCallback((value) => {
    setFormData(prevData => ({
      ...prevData,
      popup_activare_microsoft_maintainance: {
        ...prevData.popup_activare_microsoft_maintainance,
        content: value,
      }
    }))
  }, []);

  const markDownOptions = useMemo(() => {
    return {
      spellChecker: false,
      hideIcons: ['fullscreen'],
      sideBySideFullscreen: false
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current="Setări Landing"
            paths={[
              {
                name: "Home",
                url: "/",
              },
              {
                name: "settings",
                url: `/dashboard/settings`,
              },
            ]}
          />
          <Form
            className="ecommerce-form"
            action="#"
            method="post"
            onSubmit={updateSettingsLanding}
          >
            <Row>
              <Col>
                <Card className="card-modern card-big-info">
                  <Card.Body>
                    <Row>
                      <Col lg="2-5" xl="1-5">
                        <p className="card-big-info-desc">
                          Status activare
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">
                        <Form.Group as={Row} className="align-items-center">
                          <Col lg={7} xl={6}>
                            <Form.Check
                              type="switch"
                              id="isActivareEnabled"
                              name="isActivareEnabled"
                              defaultChecked={formData.isActivareEnabled == 1 ? true : false}
                              label="Status activare"
                              onChange={(e) => {
                                e.persist();
                                setFormData(prevData => ({
                                  ...prevData,
                                  isActivareEnabled: e.target.checked ? 1 : 0
                                }))
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2-5" xl="1-5">
                        <p className="card-big-info-desc">
                          Popup Activare
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">

                        <Form.Group as={Row} className="align-items-center">
                          <Col lg={7} xl={6}>
                            <Form.Check
                              type="switch"
                              id="popup_activare_microsoft_maintainance.isActive"
                              name="popup_activare_microsoft_maintainance.isActive"
                              defaultChecked={formData.popup_activare_microsoft_maintainance.isActive == 1 ? true : false}
                              label="Activare Pop-Up Maintenance"
                              onChange={(e) => {
                                e.persist();
                                setFormData(prevData => ({
                                  ...prevData,
                                  popup_activare_microsoft_maintainance: {
                                    ...prevData.popup_activare_microsoft_maintainance,
                                    isActive: e.target.checked ? 1 : 0,
                                  }
                                }))
                              }}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2-5" xl="1-5">
                        <p className="card-big-info-desc">
                          Conținut Popup
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">
                        <Form.Label>
                          Titlu
                        </Form.Label>
                        <Form.Group as={Row} className="align-items-center">
                          <Col lg={12} xl={12}>
                            <Form.Control
                              className='form-control-modern'
                              name='popup_activare_microsoft_maintainance.title'
                              value={formData.popup_activare_microsoft_maintainance.title}
                              onChange={(e) => {
                                e.persist();
                                setFormData(prevData => ({
                                  ...prevData,
                                  popup_activare_microsoft_maintainance: {
                                    ...prevData.popup_activare_microsoft_maintainance,
                                    title: e.target.value,
                                  }
                                }))
                              }
                              }
                            />
                          </Col>
                        </Form.Group>
                        <Form.Label>
                          Conținut
                        </Form.Label>
                        <Form.Group as={Row} className="align-items-center">
                          <Col lg={12} xl={12}>
                            <SimpleMdeReact value={formData.popup_activare_microsoft_maintainance.content} options={markDownOptions} onChange={onChangeMarkdown} />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2-5" xl="1-5">
                        <p className="">
                          Preț per CID
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">
                        <InputGroup className="mb-3 rounded">
                          <InputGroup.Text style={{ borderRadius: '0.25rem 0px 0px 0.25rem' }}>$</InputGroup.Text>
                          <Form.Control
                            type="number"
                            step="any"
                            id="pricePerCid"
                            name="pricePerCid"
                            defaultValue={formData.pricePerCid ?? 0}
                            label="Preț per CID"
                            placeholder="$0.00"
                            onChange={(e) => {
                              e.persist();
                              setFormData(prevData => ({
                                ...prevData,
                                pricePerCid: e.target.value,
                              }))
                            }}
                          />
                          <InputGroup.Text style={{ borderRadius: '0rem 0.25rem 0.25rem 0rem', fontSize: '0.7rem' }}>{`Avg. Price: $ ${avgCidPrice ?? "Loading..."}`}</InputGroup.Text>
                        </InputGroup>

                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-modern card-big-info">
                  <Card.Body>
                    <Row>
                      <Col className="d-flex align-items-center" lg="2-5" xl="1-5">
                        <p className="card-big-info-desc">
                          Resetare Parola Paginii
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Parola Veche
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='password'
                              className='form-control-modern'
                              name='old_password'
                              value={formData.old_password}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className='control-label text-lg-right mb-lg-0'
                          >
                            Parola Noua
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type='password'
                              className='form-control-modern'
                              name='password'
                              value={formData.password}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="action-buttons">
              <Col md="auto" className="col-6">
                <Button
                  type="submit"
                  className="btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1"
                  variant="primary"
                >
                  <i className="bx bx-save text-4 mr-2"></i> Salvează
                </Button>
              </Col>
              <Col md="auto" className="col-6 px-md-0 mt-0">
                <Button
                  onClick={() => navigate(-1)}
                  className="btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center"
                  variant="light"
                >
                  Înapoi
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
}
