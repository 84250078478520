import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getActivationAccounts(page = 1, pageSize = 12, filters, sortBy) {
    const columnsToFilter = ['orderId', 'numberOfActivations'];
    const filtersQueryParam = filters ? `&filters=${encodeURIComponent(JSON.stringify({ filters, columnsToFilter }))}` : '';
    const sortByQueryParam = sortBy ? `&sortBy=${encodeURIComponent(JSON.stringify(sortBy))}` : '';

    const url = `${API_URL}/activation-accounts?page=${page}&pageSize=${pageSize}${filtersQueryParam}${sortByQueryParam}`;

    try {
        const response = await axios.get(url, { withCredentials: true });
        return {
            data: response.data.message,
            total: response.data.total,
            totalResults: response.data.totalResults,
            totalCost: response.data.totalCost
        };
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}

export async function deleteActivationAccountEntries(id) {
    const config = {
        method: 'delete',
        url: `${API_URL}/activation-accounts/${id}`,
        withCredentials: true,
    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}

export async function updateActivationAccount(id, data) {
    const config = {
        method: 'patch',
        url: `${API_URL}/activation-accounts/${id}`,
        withCredentials: true,
        data
    };

    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        })
}