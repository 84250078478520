import { Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import PNotify from "./p-notify";


export default function CopyToClipBoardBtn({ inputId, ...props }) {
    function handleOnClick(inputId) {
        const element = document.getElementById(inputId);
        navigator.clipboard.writeText(element.value)
        return toast(
            <PNotify
                title='Success'
                icon='fas fa-check'
                text={'Copied to clipboard!'}
            />,
            {
                containerId: 'default',
                className: 'notification-success',
            }
        );
    }
    return (
        <Button
            {...props}
            type='button'
            title='Copy to clipboard'
            variant='secondary'
            onClick={() => handleOnClick(inputId)}
        >
            <i className='bx bx-clipboard'></i>
        </Button>
    )
}