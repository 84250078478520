import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPassword from './components/pages/others/forgot-password';
import SignIn from './components/pages/others/sign-in';
import Layout from './components/layout';
import Dashboard from './components/pages/dashboard';
import UserList from './components/pages/users/user-list';
import UserCreate from './components/pages/users/user-create';
import RoleList from './components/pages/roles/role-list';
import RoleCreate from './components/pages/roles/role-create';
import UserDetail from './components/pages/users/user-detail';
import RoleDetail from './components/pages/roles/role-detail';
import Logout from './components/pages/others/logout';
import ProductCodeDetail from './components/pages/product-code/product-code-detail';
import ProductCodeCreate from './components/pages/product-code/product-code-create';
import ProductCodeList from './components/pages/product-code/product-code-list';
import CatLicencesCatModulesDetail from './components/pages/catlicences-catmodules/catlicences-catmodules-detail';
import CatLicencesCatModulesCreate from './components/pages/catlicences-catmodules/catlicences-catmodules-create';
import CatLicencesCatModulesList from './components/pages/catlicences-catmodules/catlicences-catmodules-list';
import CategoryList from './components/pages/categories/category-list';
import CategoryCreate from './components/pages/categories/category-create';
import CategoryDetail from './components/pages/categories/category-detail';
import SuccessOrdersList from './components/pages/orders/success-orders-list';
import ErrorOrdersList from './components/pages/orders/error-orders-list';
import KeysList from './components/pages/keys/keys-list';
import KeysCreate from './components/pages/keys/keys-create';
import KeysDetail from './components/pages/keys/keys-detail';
import StatusList from './components/pages/statuses/statuses-list';
import StatusCreate from './components/pages/statuses/statuses-create';
import StatusDetail from './components/pages/statuses/statuses-detail';
import PageNotFound from './components/pages/others/page-not-found';
import KeyStorageList from './components/pages/key-storage/key-storage-list';
import UserContext from './utils/context/userContext';
// import KeyStorageDetail from './components/pages/key-storage/key-storage-detail';
import SupplierList from './components/pages/suppliers/suppliers-list';
import SupplierDetail from './components/pages/suppliers/suppliers-detail';
import SupplierCreate from './components/pages/suppliers/suppliers-create';
import InvoiceList from './components/pages/invoices/invoice-list';
import ActivityLogsList from './components/pages/activity-logs/activity-logs-list';
import LandingSettings from './components/pages/landing-settings/landing-settings-page';
import KeyStorageCreate from './components/pages/key-storage/key-storage-create';
import KeyStorageSearch from './components/pages/key-storage/key-storage-search';
import { getLoggedUser } from './api/auth';
import { LogProvider } from './utils/context/logContext';
import WhitelistList from './components/pages/whitelist/whitelist-list';
import WhitelistCreate from './components/pages/whitelist/whitelist-create';
import WhitelistDetail from './components/pages/whitelist/whitelist-detail';
import WhitelistLogsList from './components/pages/whitelist-logs/whitelist-logs-list';
import ActivationAccountsList from './components/pages/activation-accounts/activation-accounts-list';
import FailedActivationLogsList from './components/pages/failed-activation-logs/failed-activation-logs-list';
import WidgetReplacementLogsList from './components/pages/widget-replacement-logs/widget-replacement-logs-list';
import ResetPassword from './components/pages/others/reset-password';
import KeyReplacementWidgetLogs from './components/pages/key-storage/key-replacement-logs';

function App() {
  const [user, setUser] = useState('');

  useEffect(() => {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      async function getUser() {
        const result = await getLoggedUser();
        if (result.status === 200) {
          setUser(result.data);
        }
      }
      if (!user) {
        getUser();
      }
    }
    document.querySelector('body').classList.add('loaded');
  }, [user]);

  return (
    <React.StrictMode>
      <Router>
        <UserContext.Provider value={user}>
          <LogProvider>
            <Routes>
              <Route path='/'>
                <Route index element={<SignIn />} />
                <Route path='/login' element={<SignIn />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                {user ?
                  <Route path='/dashboard' element={<Layout user={user} />}>
                    <Route index element={<Dashboard user={user} />} />
                    <Route path='users' element={<UserList />} />
                    <Route path='users/create' element={<UserCreate />} />
                    <Route path='users/:id' element={<UserDetail />} />
                    <Route path='roles' element={<RoleList />} />
                    <Route path='roles/create' element={<RoleCreate />} />
                    <Route path='roles/:id' element={<RoleDetail />} />
                    <Route path='cod-produs' element={<ProductCodeList />} />
                    <Route path='cod-produs/create' element={<ProductCodeCreate />} />
                    <Route path='cod-produs/:id' element={<ProductCodeDetail />} />
                    <Route path='conexiuni' element={<CatLicencesCatModulesList />} />
                    <Route path='conexiuni/create' element={<CatLicencesCatModulesCreate />} />
                    <Route path='conexiuni/:id' element={<CatLicencesCatModulesDetail />} />
                    <Route path='category' element={<CategoryList />} />
                    <Route path='category/create' element={<CategoryCreate />} />
                    <Route path='category/:id' element={<CategoryDetail />} />
                    <Route path='success-orders' element={<SuccessOrdersList />} />
                    <Route path='error-orders' element={<ErrorOrdersList />} />
                    <Route path='activation-accounts' element={<ActivationAccountsList />} />
                    <Route path='cheie' element={<KeysList />} />
                    <Route path='cheie/create' element={<KeysCreate />} />
                    <Route path='cheie/:id' element={<KeysDetail />} />
                    <Route path='success-orders' element={<SuccessOrdersList />} />
                    <Route path='error-orders' element={<ErrorOrdersList />} />
                    <Route path='status' element={<StatusList />} />
                    <Route path='status/create' element={<StatusCreate />} />
                    <Route path='status/:id' element={<StatusDetail />} />
                    <Route path='suppliers' element={<SupplierList />} />
                    <Route path='suppliers/:id' element={<SupplierDetail />} />
                    <Route path='suppliers/create' element={<SupplierCreate />} />
                    <Route path='invoices' element={<InvoiceList />} />
                    <Route path='key-storage' element={<KeyStorageList />} />
                    <Route path='key-storage/create' element={<KeyStorageCreate />} />
                    <Route path='key-storage/search' element={<KeyStorageSearch />} />
                    <Route path='key-replacement-widget' element={<KeyReplacementWidgetLogs />} />
                    <Route path='activity-logs/' element={<ActivityLogsList />} />
                    <Route path='whitelist-logs/' element={<WhitelistLogsList />} />
                    <Route path='failed-activation-logs/' element={<FailedActivationLogsList />} />
                    <Route path='widget-replacement-logs/' element={<WidgetReplacementLogsList />} />
                    <Route path='landing-settings/' element={<LandingSettings />} />
                    <Route path='whitelist/' element={<WhitelistList />} />
                    <Route path='whitelist/create' element={<WhitelistCreate />} />
                    <Route path='whitelist/:id' element={<WhitelistDetail />} />
                    {/* <Route path='key-storage/:id' element={<KeyStorageDetail />} /> */}
                    <Route path='*' element={<PageNotFound />} />
                  </Route>
                  :
                  <Route path='/dashboard' element={<Layout user={user} />}>
                    <Route path='*' element={<PageNotFound />} />
                  </Route>
                }
                <Route path='/*' element={<PageNotFound />} />
              </Route>
            </Routes>
          </LogProvider>
        </UserContext.Provider>
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
