import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { getListApi as getListApiCategory } from '../../../api/category';
import { getSuppliersApi } from '../../../api/supplier';
import { getInvoicesApi } from '../../../api/invoice';
import { createKeyStorage } from '../../../api/key-storage';

export default function KeyStorageCreate() {
  const [arrayCategories, setArrayCategories] = useState([]);
  const [arraySuppliers, setArraySuppliers] = useState([]);
  const [arrayInvoices, setArrayInvoices] = useState([]);
  const [supplierValue, setSupplierValue] = useState('');
  const [keyOrigin, setKeyorigin] = useState('');
  const [invoiceName, setInvoiceName] = useState('');
  const [fileText, setFileText] = useState('Selectează un fișier');
  const [arrayFileTxt, setArrayFileTxt] = useState({});
  const [tempKeys, setTempKeys] = useState([]);
  const [category, setCategory] = useState('');
  const [isReplacementKeys, setIsReplacementKeys] = useState(false);
  const [dateAdded, setDateAdded] = useState('');

  const inputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getCategories() {
      const result = await getListApiCategory();
      if (result.status === 200) {
        setArrayCategories(result.data.message);
      }
    }

    async function getSuppliers() {
      const result = await getSuppliersApi();
      if (result.status === 200) {
        setArraySuppliers(result.data.message);
      }
    }

    async function getInvoices() {
      const result = await getInvoicesApi();
      if (result.status === 200) {
        setArrayInvoices(result.data.message);
      }
    }

    if (arrayCategories.length === 0) {
      getCategories();
    }

    if (arraySuppliers.length === 0) {
      getSuppliers();
    }

    if (arrayInvoices.length === 0) {
      getInvoices();
    }

    const lastUsedInvoiceName = localStorage.getItem('lastUsedInvoiceName');
    const lastUsedSupplier = localStorage.getItem('lastUsedSupplier');

    if (lastUsedSupplier) {
      setSupplierValue(lastUsedSupplier);
    }

    if (lastUsedInvoiceName) {
      setInvoiceName(lastUsedInvoiceName);
    }

    document.querySelector('body').classList.add('loaded');
  }, [arrayCategories, arraySuppliers, arrayInvoices]);

  async function save(event) {
    event.preventDefault();
    event.persist();

    localStorage.setItem('lastUsedSupplier', supplierValue);
    localStorage.setItem('lastUsedInvoiceName', invoiceName);

    let data = {
      keys: arrayFileTxt,
      supplier: supplierValue,
      key_origin: keyOrigin
    };

    if (isReplacementKeys && dateAdded != '') {
      data.dateAdded = dateAdded;
    } else {
      data.invoiceName = invoiceName;
    }

    const result = await createKeyStorage(data);
    if (result.status === 200 && result.data.error === 0) {
      event.target.reset();
      event.target.idSuppliers.value = supplierValue
      if (!isReplacementKeys) {
        event.target.idInvoices.value = invoiceName
      }

      setArrayFileTxt({});
      setFileText('Selectează un fișier');

      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  // event input on change
  const onChangeTxt = async (e) => {
    // reset used states
    setFileText('Selectează un fișier');
    setArrayFileTxt({});
    setTempKeys([]);

    // get file from event
    const files = e.target.files[0];

    // check file type
    const fileType = files.name.split('.').pop().toLowerCase();

    if (fileType === 'txt') {
      handleTxtFile(files);
    } else if (fileType === 'csv') {
      handleCsvFile(files);
    } else {
      // handle unsupported file types
      console.error('Unsupported file type');
      return;
    }

    setFileText(files.name);
    e.target.value = ''; // clear input field
  };

  const handleTxtFile = (files) => {
    if (inputRef.current) {
      inputRef.current.setAttribute('required', true);
      inputRef.current.removeAttribute('disabled');
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result.toString();
      const array = text.split('\n').filter(element => {
        return element.trim() !== '' && element.length > 15;
      });

      if (category) {
        setArrayFileTxt((prevValue) => ({
          ...prevValue,
          [category]: [...(prevValue[category] || []), ...array],
        }));
      } else {
        setTempKeys(array);
      }
    };
    reader.readAsText(files);
  };


  const handleCsvFile = (file) => {
    if (inputRef.current) {
      inputRef.current.removeAttribute('required');
      inputRef.current.setAttribute('disabled', true);
    }

    const fileName = file.name.split('.')[0]; // Get the file name without the extension
    const isNumeric = /^\d+$/.test(fileName);
    if (isNumeric) {
      setInvoiceName(fileName);
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result.toString();
      const lines = text.split('\n');
      const headers = lines[0].split(',').map(header => header.trim());
      const keyIndex = headers.indexOf('Key');
      const categoryIndex = headers.indexOf('ProductName');

      if (keyIndex === -1 || categoryIndex === -1) {
        console.error('CSV file must contain "Key" and "Category" columns');
        return;
      }

      const data = {};
      for (let i = 1; i < lines.length; i++) {
        const columns = lines[i].split(',');
        const key = columns[keyIndex]?.trim();
        const category = columns[categoryIndex]?.trim();

        if (key && category) {
          if (!data[category]) {
            data[category] = [];
          }
          data[category].push(key);
        }
      }

      setArrayFileTxt(data);
    };
    reader.readAsText(file);
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);

    if (tempKeys.length > 0) {
      setArrayFileTxt((prevValue) => ({
        ...prevValue,
        [newCategory]: [...(prevValue[newCategory] || []), ...tempKeys],
      }));
      setTempKeys([]); // Clear the temporary keys
    }
  };

  return (
    <>
      <Breadcrumb
        current='Adaugă storage key'
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Gestionare Chei',
            url: '#',
          },
          {
            name: 'Key Storage',
            url: '#',
          },
          {
            name: 'Adaugă key Storage',
            url: '/dashboard/key-storage/create',
          },
        ]}
      />

      <Form
        className='core-form form'
        action='#'
        method='post'
        onSubmit={save}
        id='myForm'
      >
        <Row>
          <Col>
            <Card className='card-modern card-big-info'>
              <Card.Body>
                <Row>
                  <Col lg='2-5' xl='1-5'>
                    <i className='card-big-info-icon bx bxs-user-badge'></i>
                    <h2 className='card-big-info-title'>Informații key storage</h2>
                    <p className='card-big-info-desc'>
                      Adaugă aici informații pentru key storage.
                    </p>
                  </Col>
                  <Col lg='3-5' xl='4-5'>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Categorie
                      </Col>
                      <Col lg={7} xl={6}>
                        <input
                          type='text'
                          list='idCategories'
                          name='idCategories'
                          id='inputIdCategories'
                          placeholder="Categorie"
                          className='w-100 form-control-modern form-control'
                          value={category}
                          onChange={handleCategoryChange}
                          ref={inputRef}
                        />
                        <datalist id='idCategories'>
                          {/* show modules as options in input */}
                          {typeof (arrayCategories) !== 'string' ? arrayCategories.map((item) => {
                            return <option key={item.id} value={item.name} />;
                          }) : ''}
                        </datalist>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Sursa
                      </Col>
                      <Col lg={7} xl={6}>
                        <input
                          type='text'
                          list='idOrigins'
                          name='idOrigins'
                          id='inputIdOrigins'
                          placeholder="Sursa"
                          className='w-100 form-control-modern form-control'
                          value={keyOrigin}
                          onChange={(e) => setKeyorigin(e.target.value)}
                          ref={inputRef}
                          required
                        />
                        <datalist id='idOrigins'>
                          <option value={'CSS'} />
                          <option value={'CSD'} />
                        </datalist>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Supplier
                      </Col>
                      <Col lg={7} xl={6}>
                        <input
                          type='text'
                          list='idSuppliers'
                          name='idSuppliers'
                          id='inputIdSuppliers'
                          placeholder="Supplier"
                          className='w-100 form-control-modern form-control'
                          value={supplierValue}
                          onChange={(e) => setSupplierValue(e.target.value)}
                          required
                        />
                        <datalist id='idSuppliers'>
                          {/* show modules as options in input */}
                          {typeof (arraySuppliers) !== 'string' ? arraySuppliers.map((item) => {
                            return <option key={item.id} value={item.company_name} />;
                          }) : ''}
                        </datalist>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Licente primite replacement
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Check
                          type="switch"
                          id="isActivareEnabled"
                          name="isActivareEnabled"
                          defaultChecked={isReplacementKeys}
                          onChange={(e) => {
                            e.persist();
                            setIsReplacementKeys(e.target.checked ? true : false)
                          }}
                        />
                      </Col>
                    </Form.Group>
                    {isReplacementKeys == true ? (
                      <Form.Group as={Row} className='align-items-center'>
                        <Col
                          as={Form.Label}
                          lg={5}
                          xl={3}
                          className='control-label text-lg-right mb-lg-0'
                        >
                          Replacement Data
                        </Col>
                        <Col lg={7} xl={6}>
                          <Form.Control
                            type='date'
                            name='inputInvoiceDate'
                            id='inputIdInvoicesDate'
                            placeholder="Invoice"
                            className='w-100 form-control-modern form-control'
                            value={dateAdded}
                            onChange={(e) => setDateAdded(e.target.value)}
                            required
                          />
                        </Col>
                      </Form.Group>
                    ) : (
                      <Form.Group as={Row} className='align-items-center'>
                        <Col
                          as={Form.Label}
                          lg={5}
                          xl={3}
                          className='control-label text-lg-right mb-lg-0'
                        >
                          Invoice
                        </Col>
                        <Col lg={7} xl={6}>
                          <input
                            type='text'
                            list='idInvoices'
                            name='idInvoices'
                            id='inputIdInvoices'
                            placeholder="Invoice"
                            className='w-100 form-control-modern form-control'
                            value={invoiceName}
                            onChange={(e) => setInvoiceName(e.target.value)}
                            required
                          />
                          <datalist id='idInvoices'>
                            {/* show modules as options in input */}
                            {typeof (arrayInvoices) !== 'string' ? arrayInvoices.map((item) => {
                              return <option key={item.id} value={item.invoice_name} />;
                            }) : ''}
                          </datalist>
                        </Col>
                      </Form.Group>
                    )}
                    <Form.Group as={Row} controlId='uploadTxt align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Încarcă fișierul txt/csv
                      </Col>
                      <Col lg={7} xl={6}>

                        <Form.File id='formcheck-api-custom' custom>
                          <Form.File.Input
                            className='form-control-modern form-control'
                            type='file'
                            multiple
                            accept='.txt, .csv'
                            required={!(Object.keys(arrayFileTxt).length > 0)}
                            onChange={onChangeTxt}
                          />
                          <Form.File.Label>
                            {fileText}
                          </Form.File.Label>
                        </Form.File>
                      </Col>
                    </Form.Group>
                    {/* Show read keys from file */}
                    {(Object.keys(arrayFileTxt).length > 0 || tempKeys.length > 0) && (
                      <div>
                        <p>Cheile citite</p>
                        <Col style={{ flex: "1 1", height: "280px", overflowY: "scroll", display: "flex", flexDirection: "column", background: "#e7e7e7" }}>
                          <ul style={{ marginLeft: '0', paddingInline: '10px', paddingInlineEnd: '10px' }}>
                            {Object.keys(arrayFileTxt).map((categoryKey, catIndex) => (
                              <li key={catIndex} style={{ listStyleType: 'none' }}>
                                <strong>{categoryKey}</strong>
                                <ul style={{ marginLeft: '20px' }}>
                                  {arrayFileTxt[categoryKey].map((item, key) => (
                                    <li key={key}>{item}</li>
                                  ))}
                                </ul>
                              </li>
                            ))}
                            {tempKeys.length > 0 && (
                              <li style={{ listStyleType: 'none' }}>
                                <strong>{category}</strong>
                                <ul style={{ marginLeft: '20px' }}>
                                  {tempKeys.map((item, key) => (
                                    <li key={key}>{item}</li>
                                  ))}
                                </ul>
                              </li>
                            )}
                          </ul>
                        </Col>
                      </div>
                    )}
                  </Col>
                  <Form.Row>
                  </Form.Row>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='action-buttons'>
          <Col md='auto' className='col-12'>
            <Button
              type='submit'
              className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
              variant='primary'
            >
              <i className='bx bx-save text-4 mr-2'></i>Salvează
            </Button>
          </Col>
          <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
            <Button
              onClick={() => navigate(-1)}
              className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
              variant='light'
            >
              Înapoi
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
