import { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { getStatus, retryJobs, startGenerator, stopGenerator } from '../../api/generator';

const GeneratorControlPanel = ({ user }) => {
    const adminRole = "1";
    const [message, setMessage] = useState();

    // method for get status generator
    const status = async () => {
        // method get status from api
        const status = await getStatus();
        if (status && status.status === 200) {
            // set state for message with status 200
            setMessage(status.data.message)
        } else {
            // set state for message error
            setMessage(status.data.error)
        }
    };

    // method for start generator
    const start = async () => {
        // reset state
        setMessage("");
        // method start generator from api
        const generator = await startGenerator();
        if (generator && generator.status === 200) {
            // set state for message with status 200
            setMessage(generator.data.message)
        } else {
            // set state for message error
            setMessage(generator.data.error)
        }
    };

    // method for stop generator
    const stop = async () => {
        // reset state
        setMessage("");
        // method stop generator from api
        const generator = await stopGenerator();
        if (generator && generator.status === 200) {
            // set state for message with status 200
            setMessage(generator.data.message)
        } else {
            // set state for message error
            setMessage(generator.data.error)
        }
    };

    const retryFailedJobs = async () => {
        const jobs = await retryJobs();
        if (jobs && jobs.status === 200) {
            // set state for message with status 200
            setMessage(jobs.data.message)
        } else {
            // set state for message error
            setMessage(jobs.data.error)
        }
    }

    return (
        <div className='widget-user-info'>
            <div className='widget-user-info-header'>
                <h2 className='font-weight-bold text-color-dark text-5'>
                    Hello, {user.name}
                </h2>

                <div className='widget-user-acrostic bg-primary'>
                    <span className='font-weight-bold'>Me</span>
                </div>
            </div>
            <div className='widget-user-info-body'>
                {adminRole === user.level || user.authorization.includes(adminRole) ?
                    <>
                        <Row>
                            <Col className="col-auto">
                                {setMessage && (
                                    <label>{message}</label>
                                )}
                                <h3 className="text-4-1">Verifică statusul generatorului</h3>
                                <Button
                                    className="border font-weight-semibold text-color-dark text-3"
                                    variant="light"
                                    size="s"
                                    onClick={status}
                                >
                                    Verifică
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-auto">
                                <h3 className="text-4-1">Pornește generatorul</h3>
                                <Button
                                    className="border font-weight-semibold text-color-dark text-3"
                                    variant="light"
                                    size="s"
                                    onClick={start}
                                >
                                    Pornește
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-auto">
                                <h3 className="text-4-1">Oprește generatorul</h3>
                                <Button
                                    className="border font-weight-semibold text-color-dark text-3"
                                    variant="light"
                                    size="s"
                                    onClick={stop}
                                >
                                    Oprește
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-auto">
                                <h3 className="text-4-1">Retry failed jobs</h3>
                                <Button
                                    className="border font-weight-semibold text-color-dark text-3"
                                    variant="light"
                                    size="s"
                                    onClick={retryFailedJobs}
                                >
                                    Retry
                                </Button>
                            </Col>
                        </Row>
                    </>
                    : ""
                }
            </div>
        </div>
    )
}

export default GeneratorControlPanel;