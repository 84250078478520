import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import './generic-datepicker-modal.css'
import Modal from "react-modal";
import { DatePicker, InputGroup } from 'rsuite';
import { toast } from 'react-toastify';
import PNotify from '../../elements/p-notify';
import DeleteConfirmModal from '../delete-confirm-modal';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 9999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '600px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#app');

export default function GenericDatepickerModal({ isOpen, onClose, text, confirmationMessage }) {
    const [message, setMessage] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const currentDate = new Date();
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        setMessage(text);
    }, [text])

    function closeModal(close = false) {
        if (startDate <= endDate && close === true) {
            onClose(close, startDate, endDate); 
            return;
        } 
        if (close === true) {
            onClose(close, null, null);
            toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text="Intervalul selectat nu este valid"
                />,
                {
                containerId: 'default',
                className: 'notification-danger',
                }
            );
            return;
        }
        return;
    }

    const handleOptionChange = (event) => {
        const option = event.target.value;
        setSelectedOption(option);
        var date;
        switch (option) {
            case 'Ultimul an':
              date = new Date(currentDate.getTime() - (365 * 24 * 60 * 60 * 1000));
              setStartDate(date);
              setEndDate(new Date());
              break;
            case 'Ultima luna':
              date = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));
              setStartDate(date);
              setEndDate(new Date());
              break;
            case 'Ultima saptamana':
              date = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
              setStartDate(date);
              setEndDate(new Date());
              break;
            case 'Ultima zi':
              date = new Date();
              setStartDate(date);
              setEndDate(new Date());
              break;
            default:
              setStartDate(new Date());
              setEndDate(new Date());
              break;
          }
    }

    async function deleteConfirm(result) {
        setOpenConfirmModal(false);
        closeModal(result);
    }

    return (<>
        <Modal
            isOpen={isOpen}
            style={modalStyles}
            onRequestClose={() => closeModal(false)}
        >
            <Card>
                <Card.Header>
                    <Card.Title>Necesita actiune:</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="modal-wrapper">
                        <div className="modal-text">
                            <h5 className="mb-0">{message}</h5>
                        </div>
                    </div>
                    <h5>Alegeti un interval:</h5>
                    <div className='datepicker-options'>
                        <label>
                            <input
                                type="radio"
                                value="Customizat"
                                checked={selectedOption === 'Customizat'}
                                onChange={handleOptionChange}
                            />
                            &ensp;Customizat:
                        </label>
                        {selectedOption === 'Customizat' && (<>
                            <InputGroup style={{ width: 428 }}>
                                <DatePicker block appearance="subtle"
                                    style={{ width: 230 }}
                                    placeholder="Data initiala" 
                                    value={startDate}
                                    onChange={(selectedDate) => setStartDate(selectedDate)}
                                />
                                <InputGroup.Addon> - </InputGroup.Addon>
                                <DatePicker block appearance="subtle"
                                    style={{ width: 230 }}
                                    placeholder="Data finala" 
                                    value={endDate}
                                    onChange={(selectedDate) => setEndDate(selectedDate)}
                                />
                            </InputGroup></>)}
                        <label>
                            <input
                                type="radio"
                                value="Ultimul an"
                                checked={selectedOption === 'Ultimul an'}
                                onChange={handleOptionChange}
                            />
                            &ensp;Ultimul an:
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Ultima luna"
                                checked={selectedOption === 'Ultima luna'}
                                onChange={handleOptionChange}
                            />
                            &ensp;Ultima luna:
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Ultima saptamana"
                                checked={selectedOption === 'Ultima saptamana'}
                                onChange={handleOptionChange}
                            />
                            &ensp;Ultima saptamana:
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="Ultima zi"
                                checked={selectedOption === 'Ultima zi'}
                                onChange={handleOptionChange}
                            />
                            &ensp;Ultima zi:
                        </label>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={() => setOpenConfirmModal(true)}
                            >Confirmă</Button>
                            <Button
                                className="modal-dismiss"
                                variant="default"
                                onClick={() => closeModal(false)}
                            >Anulează</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
        <DeleteConfirmModal 
            isOpen={openConfirmModal} 
            onClose={(result) => deleteConfirm(result)} 
            message={confirmationMessage}/>
        </>
    )
}