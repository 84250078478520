import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Breadcrumb from '../../common/breadcrumb';
import DeleteConfirmModal from '../../../features/modals/delete-confirm-modal';
import Loader from '../../../features/loader';
import PNotify from '../../../features/elements/p-notify';

import { deleteSuppliersApi, getSupplier, updateSupplierApi } from '../../../api/supplier';


export default function SupplierDetail() {
    const [item, setItem] = useState(null);
    const [itemOld, setItemOld] = useState(null);

    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getSupplier(id).then((result) => {
            setItem(result.data.message);
            setItemOld(result.data.message);
            setLoading(false);
        });
        document.querySelector('body').classList.add('loaded');
    }, [id, setItem, setItemOld]);

    async function updateItem(e) {
        e.preventDefault();

        let data = {
            company_name: e.target.company_name.value ?? itemOld.company_name,
            vat: e.target.vat.value ?? itemOld.vat,
            alias: e.target.alias.value ?? itemOld.alias
        };

        const result = await updateSupplierApi(data, id);
        if (result.status === 200 && result.data.error === 0) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/suppliers`);
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    function deleteItem(e) {
        e.preventDefault();
        setOpenModal(true);
    }

    async function deleteConfirm(result) {
        setOpenModal(false);
        if (result) {
            navigate(`${process.env.PUBLIC_URL}/dashboard/suppliers`);

            const checker = await deleteSuppliersApi(id);
            if (checker.status === 200 && checker.data.error === 0) {
                navigate(`${process.env.PUBLIC_URL}/dashboard/suppliers`);
                return toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={checker.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                return toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={checker.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Breadcrumb
                        current='Editeză Supplier'
                        paths={[
                            {
                                name: 'Dashboard',
                                url: '/dashboard',
                            },
                            {
                                name: 'Gestionare Chei',
                                url: '#',
                            },
                            {
                                name: 'Suppliers',
                                url: '#',
                            },
                            {
                                name: 'Editeză Supplier',
                                url: '#',
                            },
                        ]}
                    />

                    <Form
                        className='ecommerce-form'
                        action='#'
                        method='post'
                        onSubmit={updateItem}
                    >
                        <Row>
                            <Col>
                                <Card className='card-modern card-big-info'>
                                    <Card.Body>
                                        <Row>
                                            <Col lg='2-5' xl='1-5'>
                                                <i className='card-big-info-icon bx bx-user-circle'></i>
                                                <h2 className='card-big-info-title'>Informații Supplier</h2>
                                                <p className='card-big-info-desc'>
                                                    Adaugă aici informații pentru supplier.
                                                </p>
                                            </Col>
                                            <Col lg='3-5' xl='4-5'>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Col
                                                        as={Form.Label}
                                                        lg={5}
                                                        xl={3}
                                                        className='control-label text-lg-right mb-lg-0'
                                                    >
                                                        Nume Companie
                                                    </Col>
                                                    <Col lg={7} xl={6}>
                                                        <Form.Control
                                                            type='text'
                                                            maxLength='30'
                                                            className='form-control-modern'
                                                            name='company_name'
                                                            defaultValue={item.company_name}
                                                            required
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Col
                                                        as={Form.Label}
                                                        lg={5}
                                                        xl={3}
                                                        className='control-label text-lg-right mb-lg-0'
                                                    >
                                                        VAT
                                                    </Col>
                                                    <Col lg={7} xl={6}>
                                                        <Form.Control
                                                            type='number'
                                                            maxLength='30'
                                                            className='form-control-modern'
                                                            name='vat'
                                                            defaultValue={item.vat}
                                                            required
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className='align-items-center'>
                                                    <Col
                                                        as={Form.Label}
                                                        lg={5}
                                                        xl={3}
                                                        className='control-label text-lg-right mb-lg-0'
                                                    >
                                                        Alias
                                                    </Col>
                                                    <Col lg={7} xl={6}>
                                                        <Form.Control
                                                            type='text'
                                                            maxLength='30'
                                                            className='form-control-modern'
                                                            name='alias'
                                                            defaultValue={item.alias}
                                                            required
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='action-buttons'>
                            <Col md='auto' className='col-6'>
                                <Button
                                    type='submit'
                                    className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                                    variant='primary'
                                >
                                    <i className='bx bx-save text-4 mr-2'></i> Salvează
                                </Button>
                            </Col>
                            <Col md='auto' className='col-6 px-md-0 mt-0'>
                                <Button
                                    onClick={() => navigate(-1)}
                                    className='btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center'
                                    variant='light'
                                >
                                    Înapoi
                                </Button>
                            </Col>
                            <Col md='auto' className='col-6 ml-md-auto mt-3 mt-md-0'>
                                <Button
                                    href='#delete'
                                    className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                                    variant='danger'
                                    onClick={deleteItem}
                                >
                                    <i className='bx bx-trash text-4 mr-2'></i> Șterge
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}

            <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
        </>
    );
}
