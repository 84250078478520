import React, { useContext, useEffect, useState, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import LogContext from '../../utils/context/logContext';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export default function LogViewer() {
    const [logs, setLogs] = useState(LogContext);
    const scrollbarsRef = useRef(null);

    // Function to create and initialize the WebSocket connection
    const getLogs = async () => {
        const config = {
            method: 'get',
            url: `${API_URL}/data/logs`,
            withCredentials: true        
          };
          return axios(config)
            .then((response) => {
                return response.data.split('\n');
            })
            .catch((error) => {
              return error.response;
            });
    };

    useEffect(() => {
        // async function logsAsync() {
        //     // You can await here
        //     const logs = await getLogs();
        //     setLogs(logs);
        // }
        // if(!logs.length > 0) {
        //     logsAsync();
        // }

        if (scrollbarsRef.current) {
            scrollbarsRef.current.scrollToBottom();
        }
    }, [logs]);

    const handleRefresh = async () => {
        setLogs("")
        // Initialize a new WebSocket connection
        const logs = await getLogs();
        setLogs(logs)
    };

    return (
        <>
            <Button
                className='font-weight-semibold mb-3'
                variant='primary'
                size='md'
                onClick={handleRefresh}
            >
                Refresh
            </Button>
            <Scrollbars
                style={{ height: 200 }}
                autoHide
                autoHeight
                autoHeightMin={200} // Minimum height to enable auto-height scrolling
                ref={scrollbarsRef}
            >
                {logs && logs.length > 0 ? 
                    logs.map((log, index) => (
                        <div key={index}>{log}</div>
                    )) : <div>No logs available</div>}
            </Scrollbars>
        </>
    );
}
