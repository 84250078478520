import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getInvoicesApi() {
  const result = await getInvoices();
  if (result.status === 200) {
    return result;
  }
}

export async function getFilteredInvoices(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response.data.error
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              return (
                (product.invoice_name && product.invoice_name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.created_at && (new Date(product.created_at).toLocaleString()).search(new RegExp(filter.value, 'i')) >= 0) ||
                (product?.supplier?.company_name && product.supplier.company_name.search(new RegExp(filter.value, 'i')) >= 0)
              )
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'invoice_name':
                results = results.sort((a, b) =>
                  a.name < b.name ? -index : index
                );
                break;
              case 'supplier':
                results = results.sort((a, b) =>
                  a.supplier.company_name < b.supplier.company_name ? -index : index
                );
                break;
              case 'created_at':
                results = results.sort((a, b) =>
                  a.supplier.created_at < b.supplier.created_at ? -index : index
                );
                break;
              default:
                break;
            }
          });
        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createInvoice(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/invoices`,
    withCredentials: true,
    data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateInvoicesApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/invoices/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteInvoicesApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/invoices/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getInvoices() {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getInvoice(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/invoices/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}