import { roles } from "./role"
const menuData = [{
    "name": "Dashboard",
    "icon": "bx bx-home",
    "url": "/dashboard"
},
{
    "name": "Panou pentru intretinere CEL",
    "icon": "bx bx-briefcase",
    "url": "#",
    "role": [roles.keymaster, roles.support],
    "children": [
        {
            "name": "Cod produs",
            "url": "#",
            "role": [roles.keymaster],
            "children": [{
                "name": "Coduri produse",
                "url": "/dashboard/cod-produs",
                "role": [roles.keymaster]
            },
            {
                "name": "Adaugă cod produs",
                "url": "/dashboard/cod-produs/create",
                "role": [roles.keymaster]
            }
            ]
        },
        {
            "name": "Categoriile pentru licențe",
            "url": "#",
            "role": [roles.keymaster],
            "children": [{
                "name": "Listează categoriile",
                "url": "/dashboard/category",
                "role": [roles.keymaster]
            },
            {
                "name": "Adaugă categorie",
                "url": "/dashboard/category/create",
                "role": [roles.keymaster]
            }
            ]
        },
        {
            "name": "Conexiune între cod și categorie",
            "url": "#",
            "role": [roles.keymaster],
            "children": [{
                "name": "Conexiuni",
                "url": "/dashboard/conexiuni",
                "role": [roles.keymaster]
            },
            {
                "name": "Adaugă conexiune",
                "url": "/dashboard/conexiuni/create",
                "role": [roles.keymaster]
            }
            ]
        },
        {
            "name": "Cheile de licență",
            "url": "#",
            "role": [roles.keymaster],
            "children": [{
                "name": "Cheile",
                "url": "/dashboard/cheie",
                "role": [roles.keymaster]
            },
            {
                "name": "Adaugă cheie de licență",
                "url": "/dashboard/cheie/create",
                "role": [roles.keymaster]
            }
            ]
        },
        {
            "name": "Comenzi",
            "url": "#",
            "role": [roles.support, roles.keymaster],
            "children": [{
                "name": "Comenzile finalizate",
                "url": "/dashboard/success-orders",
                "role": [roles.support, roles.keymaster]
            },
            {
                "name": "Comenzile problemă",
                "url": "/dashboard/error-orders",
                "role": [roles.support, roles.keymaster]
            }
            ]
        },
        {
            "name": "Conturi Activare",
            "url": "#",
            "role": [roles.admin],
            "children": [
                {
                    "name": "Conturi Activare",
                    "url": "/dashboard/whitelist",
                    "role": [roles.admin],
                },
                {
                    "name": "Adaugă în conturi activare",
                    "url": "/dashboard/whitelist/create",
                    "role": [roles.admin],
                },
            ]
        },
        {
            "name": "Comenzi Activare CEL",
            "url": "/dashboard/activation-accounts",
            "role": [roles.support, roles.keymaster],
        },
        // {
        //     "name": "Generator",
        //     "role": roles.admin,
        //     "url": "/dashboard/generator",
        // }
    ]
},
{
    "name": "Gestionare Chei",
    "icon": "bx bx-key",
    "url": "#",
    "role": [roles.keymaster, roles.support],
    "children": [
        {
            "name": "Search",
            "url": "/dashboard/key-storage/search",
            "role": [roles.keymaster, roles.support],
        },
        {
            "name": "Invoices",
            "url": "/dashboard/invoices",
            "role": [roles.keymaster],
        },
        {
            "name": "Suppliers",
            "url": "/dashboard/suppliers",
            "role": [roles.keymaster],
        },
        {
            "name": "Key Storage",
            "url": "/dashboard/key-storage",
            "role": [roles.keymaster]
        },
        {
            "name": "Înlocuiri",
            "url": "/dashboard/key-replacement-widget",
            "role": [roles.keymaster]
        }
    ]
},
{
    "name": "Settings",
    "icon": "bx bx-cog",
    "url": "#",
    "role": [roles.admin],
    "children": [{
        "name": "Useri",
        "url": "#",
        "role": [roles.admin],
        "children": [{
            "name": " Useri",
            "url": "/dashboard/users",
            "role": [roles.admin]
        },
        {
            "name": "Adaugă Useri",
            "url": "/dashboard/users/create",
            "role": [roles.admin]
        }
        ]
    },
    {
        "name": "Roluri",
        "url": "#",
        "role": [roles.admin],
        "children": [{
            "name": " Roluri",
            "url": "/dashboard/roles",
            "role": [roles.admin]
        },
        {
            "name": "Adaugă Roluri",
            "url": "/dashboard/roles/create",
            "role": [roles.admin]
        }
        ]
    },
    {
        "name": "Statusuri",
        "url": "#",
        "role": [roles.admin],
        "children": [
            {
                "name": "Statusuri",
                "url": "/dashboard/status",
                "role": [roles.admin],
            },
            {
                "name": "Adaugă Statusuri",
                "url": "/dashboard/status/create",
                "role": [roles.admin],
            },
        ]
    },
    {
        "name": "Logs",
        "url": "#",
        "role": [roles.admin],
        "children": [
            {
                "name": "Activity Logs",
                "url": "/dashboard/activity-logs",
                "role": [roles.admin],
            },
            {
                "name": "Conturi Activare Logs",
                "url": "/dashboard/whitelist-logs",
                "role": [roles.admin],
            },
            {
                "name": "Loguri activări eșuate",
                "url": "/dashboard/failed-activation-logs",
                "role": [roles.admin],
            },
            {
                "name": "Loguri înlocuire licențe",
                "url": "/dashboard/widget-replacement-logs",
                "role": [roles.admin],
            }
        ]
    },
    {
        "name": "Setări landing",
        "url": "/dashboard/landing-settings",
        "role": [roles.admin],
    }
    ]
}
];

export default menuData;