import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';
import { getFailedActivationLogsWithFilter } from '../../../api/failed-activation-logs';
import * as XLSX from 'xlsx';
import { format } from 'rsuite/esm/utils/dateUtils';

const FailedActivationLogsList = () => {

    const [loading, setLoading] = useState(true);
    const [ajax, setAjax] = useState({
        data: [],
        total: 0,
    });
    const [search, setSearch] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [tableRef, setTableRef] = useState(null);
    const [selected, setSelected] = useState([]);
    const [bulk, setBulk] = useState('');

    const columns = [
        {
            Header: 'ID de Instalare',
            accessor: 'iid',
            sortable: true,
            Cell: ({ value }) => { return value.replace(/(.{7})/g, '$1-').slice(0, -1) }
        },
        {
            Header: 'Cont/Comanda',
            id: 'accountDetails',
            accessor: (d) => d?.accountDetails?.email || d?.accountDetails?.orderId || '-',
            sortable: true,
        },
        {
            Header: 'Mesaj de eroare',
            accessor: 'errorMessage',
            sortable: true,
        },
        {
            Header: 'Creat La',
            accessor: 'createdAt',
            sortable: true,
            Cell: ({ value }) => (new Date(value).toLocaleString()),
        },
    ];

    useEffect(() => {
        setSelected((selected) =>
            selected.map((item) => {
                return {
                    ...item,
                    selected: selectAll,
                };
            })
        );
        document.querySelector('body').classList.add('loaded');
    }, [selectAll]);

    function isSelected(key) {
        return selected.find((item) => item.id === key && item.selected);
    }

    function onSelectChange(e, value, row) {
        setSelected(
            selected.map((item) => {
                if (item.id === row.id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            })
        );
    }

    async function bulkAction(e) {
        e.preventDefault();
        if (!bulk) {
            return toast(
                <PNotify
                    title='Warning'
                    icon='fas fa-exclamation'
                    text='Please choose one of actions.'
                />,
                {
                    containerId: 'default',
                    className: 'notification-warning',
                }
            );
        }
    }

    function fetchData(state) {
        let filtered = [...state.filtered];
        setLoading(true);
        getFailedActivationLogsWithFilter(
            state.page * state.pageSize,
            (state.page + 1) * state.pageSize,
            filtered,
            state.sorted
        ).then((results) => {
            if (results?.data) {
                setAjax({
                    data: results.data,
                    total:
                        parseInt(results.total / state.pageSize) +
                        !!(results.total % state.pageSize),
                    totalRecords: results.totalResults,
                    totalFilteredRecords: results.total,
                    isFiltered: filtered.length ? true : false,
                });
                setSelected(
                    results.data.map((media) => {
                        return {
                            id: media.id,
                            selected: false,
                        };
                    })
                );
                setSelectAll(false);
            }
            setLoading(false);
        });
    }

    function searchWhitelistLogs(e) {
        e.preventDefault();
        tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
    }

    const handleExport = async () => {
        // const rows = await getExportData();
        const rows = ajax.data.reduce((acc, obj) => {
            acc.push({
                id: obj.id,
                iid: obj.iid,
                accountDetails: obj.accountDetails?.email || obj.accountDetails?.orderId || '-',
                errorMessage: obj.errorMessage,
                createdAt: obj.createdAt,
            });
            return acc;
        }, []);
        if (!rows) { return }
        let objectMaxLength = await getExcelColumnWidths(rows);
        const wcols = objectMaxLength.map((value) => ({ wch: value })); //wch - width based on character
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);
        worksheet['!cols'] = wcols; // Add column widths

        XLSX.utils.sheet_add_aoa(worksheet, [
            ["ID", "IID", "Cont", "Mesaj de eroare", "Creat la"],
        ], { origin: 0 }) // Add table headers

        XLSX.utils.book_append_sheet(workbook, worksheet, "IDuri de instalare");

        XLSX.writeFile(workbook, `IDuri_de_instalare_${format(new Date(), 'dd_mm_yyyy')}.xlsx`, { type: 'buffer', bookType: 'xlsx' });

    }

    async function getExcelColumnWidths(rows) {
        // TODO DELETE THIS IF APPLICATION TOO SLOW AND ADD FIXED WIDTH VALUE
        let objectMaxLength = [];
        for (let i = 0; i < rows.length; i++) {
            let value = Object.values(rows[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : value[j].length;
                }
            }
        }
        //
        return objectMaxLength;
    }

    return (
        <>
            <Breadcrumb
                current={'Conturi Activare Logs'}
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'Settings',
                        url: '#',
                    },
                    {
                        name: 'Activity Logs',
                        url: '#',
                    },
                    {
                        name: 'Listează Conturi Activare Logs',
                        url: '/dashboard/whitelist-logs',
                    },
                ]}
            />

            <Row>
                <Col>
                    <Form method='GET' action='#' onSubmit={searchWhitelistLogs}>
                        <Card className='card-modern'>
                            <Card.Body>
                                <div className='datatables-header-footer-wrapper'>
                                    <div className='datatable-header'>
                                        <Row className='align-items-lg-center mb-3'>
                                            <Col lg={4} xl={2}>
                                                <div className='d-flex align-items-lg-center flex-wrap'>
                                                    <Button onClick={handleExport} className='btn btn-warning'><i className='fas fa-file-excel'></i> Export</Button>
                                                </div>
                                            </Col>
                                            <Col className='col-auto pl-lg-1 ml-auto'>
                                                <div className='search search-style-1 mx-lg-auto w-auto'>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type='text'
                                                            className='search-term'
                                                            placeholder='Search'
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                        />
                                                        <InputGroup.Append>
                                                            <Button variant='default' type='submit'>
                                                                <i className='bx bx-search'></i>
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <PtTable
                                        className='table table-core-simple -striped mb-0'
                                        data={ajax.data}
                                        loading={loading}
                                        columns={columns}
                                        pages={ajax.total}
                                        pageSize={12}
                                        manual
                                        onFetchData={fetchData}
                                        selectAll={selectAll}
                                        toggleAll={() => setSelectAll(!selectAll)}
                                        isSelected={(key) => isSelected(key)}
                                        toggleSelection={onSelectChange}
                                        onChangeRef={(ref) => setTableRef(ref)}
                                        totalRecords={ajax.totalRecords}
                                        totalFilteredRecords={ajax.totalFilteredRecords}
                                        isFiltered={ajax.isFiltered}
                                    />

                                    <div className='datatable-footer'>
                                        <Row className='align-items-center justify-content-between mt-3'>
                                            <Col md='auto' className='mb-3 mb-lg-0'>
                                                <div className='d-flex'>
                                                    <Form.Control
                                                        as='select'
                                                        className='select-style-1 bulk-action w-auto mr-3'
                                                        value={bulk}
                                                        onChange={(e) => setBulk(e.target.value)}
                                                        style={{ minWidth: '120px' }}
                                                    >
                                                        <option value=''>Bulk Actions</option>
                                                    </Form.Control>
                                                    <Button
                                                        href='#bulk-action'
                                                        className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                                                        variant='light'
                                                        onClick={bulkAction}
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg='auto' className='mb-3 mb-lg-0'>
                                                <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </>
    );

}

export default FailedActivationLogsList;