import axios from 'axios';
import { sortByAB } from './other';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getListApi() {
  const result = await getApi();
  if (result.status === 200) {
    return result;
  }
}

export async function getFiltered(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/modules`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let results = response.data.message;
      let totalResults = response.data.message.length;
      filters &&
        filters.forEach((filter) => {
          results = results.filter((product) => {
            return (
              (product.id && product.id.toString().search(new RegExp(filter.value, 'i')) >= 0) ||
              (product.keys_model && product.keys_model.search(new RegExp(filter.value, 'i')) >= 0))
          });
        });

      sortBy &&
        sortBy.forEach((sort) => {
          let index = sort.desc ? -1 : 1;
          switch (sort.id) {
            case 'id':
              results = results.sort((a, b) =>
                sortByAB(a.id, b.id, index, sort)
              );
              break;
            case 'keys_model':
              results = results.sort((a, b) =>
                sortByAB(a.keys_model, b.keys_model, index, sort)
              );
              break;
            default:
              break;
          }
        });

      return {
        data: results.slice(from, to),
        total: results.length,
        totalResults: totalResults,
      };
    })
    .catch((error) => console.error(error));
}

export async function createApi(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/data/modules`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/data/modules/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/data/modules/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getApi() {
  const config = {
    method: 'get',
    url: `${API_URL}/data/form/modules`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getApiById(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/modules/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}