import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getStatusesApi(tableName) {
  const result = await getStatuses(tableName);
  if (result.status === 200) {
    return result;
  }
}

export async function getFiltered(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/status`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let results = response.data.message;
      let totalResults = response.data.message.length;
      filters &&
        filters.forEach((filter) => {
          results = results.filter((product) => {
            const isPrimary = product.is_primary ? 'Da' : 'Nu';
            return (
              (product.name && product.name.search(new RegExp(filter.value, 'i')) >= 0) ||
              (product.table_name && product.table_name.search(new RegExp(filter.value, 'i')) >= 0) ||
              (product.table_order.toString() && product.table_order.toString().search(new RegExp(filter.value, 'i')) >= 0) ||
              (product.color && product.color.search(new RegExp(filter.value, 'i')) >= 0) ||
              (isPrimary && isPrimary.search(new RegExp(filter.value, 'i')) >= 0)
            )
          });
        });

      sortBy &&
        sortBy.forEach((sort) => {
          let index = sort.desc ? -1 : 1;
          switch (sort.id) {
            case 'name':
              results = results.sort((a, b) =>
                a.name < b.name ? -index : index
              );
            break;
            case 'table_name':
              results = results.sort((a,b) => 
                a.table_name < b.table_name ? -index : index
              );
            break;
            case 'table_order':
              results = results.sort((a,b) => 
                a.table_order < b.table_order ? -index : index
              );
            break;
            case 'color':
              results = results.sort((a,b) => 
                a.color < b.color ? -index : index
              );
            break;
            case 'is_primary':
              results = results.sort((a,b) => 
                a.is_primary < b.is_primary ? -index : index
              );
            break;
            default:
            break;
          }
        });

      return {
        data: results.slice(from, to),
        total: results.length,
        totalResults: totalResults,
      };
    })
    .catch((error) => console.error(error));
}

export async function createStatus(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/status`,
    withCredentials: true,
    data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateStatusApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/status/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteStatusApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/status/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getStatuses(tableName) {
  const config = {
    method: 'get',
    url: `${API_URL}/status`,
    withCredentials: true,
    params: {
      tableName: tableName
    }
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getStatus(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/status/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}