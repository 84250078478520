import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";
import PNotify from "../elements/p-notify";
import { getOrder, resendOrderEmail, sendReplacementKeys } from "../../api/generator";
import CopyToClipBoardBtn from "../elements/copy-to-clipboard-btn";
import Modal from "react-modal";

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999,
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '1200px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxHeight: '90%',
    }
};

Modal.setAppElement('#app');

const OrderSearch = ({ user }) => {
    const formRef = useRef();
    const textareaRef = useRef(null);
    const [searchedOrder, setSearchedOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoadingEmail, setIsLoadingEmail] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [modalSettings, setModalSettings] = useState({
        emailModal: {
            isOpen: false,
            email: null,
        },
        licensesModal: {
            isOpen: false,
            email: null,
            customEmail: '',
        }
    })

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [searchedOrder?.purchasedLicenses]);


    const searchOrder = async (e) => {
        e.preventDefault();
        setLoading((prev) => !prev)
        const id = e.target.orderId.value;
        const result = await getOrder(id);
        if (result.status === 200 && !result.data.error) {
            setSearchedOrder(result.data.message.message);
            setLoading((prev) => !prev)
        } else {
            setLoading((prev) => !prev)
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    const handleCheckboxChange = (key) => {
        setSelectedKeys(prevSelectedKeys => {
            // Check if the key is already selected
            const isSelected = prevSelectedKeys.some(selected => selected.id === key.id);
            if (isSelected) {
                // Remove the key if it is already selected
                return prevSelectedKeys.filter(selected => selected.id !== key.id);
            } else {
                // Add the key if it is not selected
                return [...prevSelectedKeys, key];
            }
        });
    };

    const handleEmailResend = (e) => {
        e.preventDefault();
        setModalSettings({
            ...modalSettings,
            emailModal: {
                isOpen: true,
                email: searchedOrder.order_email,
            }
        });
    }

    const handleInstantEmailResend = async (e) => {
        e.preventDefault();
        let toastResult = null;
        setIsLoadingEmail(true);

        const data = {
            orderId: searchedOrder.id_comanda,
            email: searchedOrder.order_email,
        }

        const result = await resendOrderEmail(data);

        if (result.status === 200 && !result.data.error) {
            toastResult = toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            toastResult = toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
        setIsLoadingEmail(false);
        return toastResult;
    }

    const onCloseEmail = async (result) => {
        let toastResult = null;
        if (result != false) {
            setIsLoadingEmail(true);
            const data = {
                orderId: searchedOrder.id_comanda,
                email: modalSettings.emailModal.email,
            }
            const result = await resendOrderEmail(data);

            if (result.status === 200 && !result.data.error) {
                toastResult = toast(
                    <PNotify
                        title='Success'
                        icon='fas fa-check'
                        text={result.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-success',
                    }
                );
            } else {
                toastResult = toast(
                    <PNotify
                        title='Error'
                        icon='fas fa-check'
                        text={result.data.message}
                    />,
                    {
                        containerId: 'default',
                        className: 'notification-danger',
                    }
                );
            }
        }
        setIsLoadingEmail(false);
        setModalSettings({
            ...modalSettings,
            emailModal: {
                isOpen: false,
                email: null,
            }
        });
        return toastResult;
    }

    const handleResendLicenses = (key) => {
        if (key) {
            setSelectedKeys([key]);
        }
        setModalSettings({
            ...modalSettings,
            licensesModal: {
                ...modalSettings.licensesModal,
                isOpen: true,
                email: searchedOrder.order_email,
            }
        });
    }

    const onCloseReplacementLicenses = () => {
        setSelectedKeys([]);
        setModalSettings({
            ...modalSettings,
            licensesModal: {
                ...modalSettings.licensesModal,
                isOpen: false,
                email: '',
            }
        });

    }

    const keyReplacementSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingEmail(true);

        const textareas = e.target.querySelectorAll('textarea');
        const replacementsByCategory = {};

        textareas.forEach(textarea => {
            const name = textarea.name;
            const id = textarea.id;
            const categoryMatch = name.match(/replacement_keys_category_(.+)/);
            const categoryIdMatch = id.match(/replacement_keys_category_(\d+)/);

            if (categoryMatch) {
                const categoryName = categoryMatch[1]; // Extract categoryName
                const categoryId = categoryIdMatch ? categoryIdMatch[1] : null;

                // Find the matching textarea for original keys
                const originalTextarea = e.target.querySelector(`textarea[name="keys_category_${categoryName}"]`);
                const originalKeys = originalTextarea ? originalTextarea.value.split('\n').map(value => value.trim()).filter(value => value !== '') : [];

                const replacements = textarea.value
                    .split('\n')
                    .map(value => value.trim())
                    .filter(value => value !== '');

                // Only update replacementsByCategory if there are valid replacements and valid original keys
                if (replacements.length > 0 && originalKeys.length > 0) {
                    const keysReplacementsPair = originalKeys.map((key, index) => ({
                        originalKey: key,
                        replacementKey: replacements[index] || null // Match original keys with replacements
                    })).filter(pair => pair.originalKey && pair.replacementKey); // Filter out invalid pairs

                    if (keysReplacementsPair.length > 0) {
                        replacementsByCategory[categoryName] = {
                            category: categoryName,
                            categoryId: categoryId,
                            replacements: keysReplacementsPair
                        };
                    }
                }
            }
        });

        const email = modalSettings.licensesModal.customEmail !== ''
            ? modalSettings.licensesModal.customEmail
            : modalSettings.licensesModal.email;

        // Normalize the data structure
        const data = {
            replacementKeys: Object.values(replacementsByCategory),
            email,
            userId: user.id
        };

        const result = await sendReplacementKeys(data);

        let toastResult;
        if (result.status === 200 && !result.data.error) {
            toastResult = toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            toastResult = toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }

        setIsLoadingEmail(false);
        setModalSettings({
            ...modalSettings,
            licensesModal: {
                isOpen: false,
                email: null,
            }
        });
        setSelectedKeys([]);
        return toastResult;
    };



    return (
        <>
            <Row>
                <Col>
                    <Form className='core-form form'
                        action='#'
                        method='post'
                        onSubmit={searchOrder}
                        id='myForm'
                        ref={formRef}
                    >
                        <Card>
                            <Card.Body>
                                <Row lg={4} xl={3} className='d-flex justify-content-start align-items-stretch'>
                                    <Col xs={12} lg={6} xl={6} className='align-self-start'>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Form.Label># Comandă</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                    type='text'
                                                    maxLength='30'
                                                    className='form-control-modern p-2'
                                                    style={{ borderRadius: '0.5rem 0 0 0.5rem' }}
                                                    name='orderId'
                                                    placeholder="ex: 12345"
                                                />
                                                <Button type="submit" variant="secondary" style={{ borderRadius: '0 0.5rem 0.5rem 0' }}><i className="fa fa-search"></i></Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>
            {loading && (
                <Row>
                    <Col lg={12} xl={12} className="d-flex justify-content-center">
                        <Card>
                            <Card.Body>
                                <Spinner animation="border" role="status" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            {searchedOrder && !loading && (
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {loading ? (
                                    <Row>
                                        <Col lg={12} xl={12} className="d-flex justify-content-center">
                                            <Spinner animation="border" role="status" />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col lg={12} xl={12}>
                                            <Form.Group as={Row} className='align-items-center'>
                                                <Col
                                                    as={Form.Label}
                                                    lg={3}
                                                    xl={3}
                                                    className='control-label text-lg-left mb-lg-0'
                                                >
                                                    Comanda # :
                                                </Col>
                                                <Col lg={8} xl={8}>
                                                    <input
                                                        type='text'
                                                        name='orderId'
                                                        id={`orderId`}
                                                        placeholder="Key"
                                                        className='w-100 form-control-modern form-control'
                                                        value={searchedOrder?.id_comanda ?? ''}
                                                        readOnly
                                                        disabled
                                                    />
                                                </Col>
                                                <Col sm={12} lg={1} xl={1} className='d-flex justify-content-between justify-content-lg-start'>
                                                    <CopyToClipBoardBtn
                                                        className='w-100 w-lg-auto'
                                                        inputId={`orderId`}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group>
                                                <Row className="mb-5 mt-5">
                                                    <Form.Group as={Col} lg={6} className='align-items-center mb-0'>
                                                        <Row className='align-items-center'>
                                                            <Col
                                                                as={Form.Label}
                                                                lg={2}
                                                                className='control-label text-lg-left mb-lg-0 text-nowrap'
                                                            >
                                                                Data plății :
                                                            </Col>
                                                            <Col lg={9}>
                                                                <input
                                                                    type='text'
                                                                    name='paymentDate'
                                                                    id={`paymentDate`}
                                                                    placeholder="Key"
                                                                    className='w-100 form-control-modern form-control'
                                                                    value={new Date(searchedOrder?.date_purchased).toLocaleString() ?? ''}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <Col lg={1} className='d-flex justify-content-between justify-content-lg-start'>
                                                                <CopyToClipBoardBtn
                                                                    className='w-100 w-lg-auto'
                                                                    inputId={`paymentDate`}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    <Form.Group as={Col} lg={6} className='align-items-center mb-0 pt-0 border-0'>
                                                        <Row className='align-items-center'>
                                                            <Col
                                                                as={Form.Label}
                                                                lg={2}
                                                                className='control-label text-lg-left mb-lg-0 text-nowrap'
                                                            >
                                                                Data emailului :
                                                            </Col>
                                                            <Col lg={9}>
                                                                <input
                                                                    type='text'
                                                                    name='emailDate'
                                                                    id={`emailDate`}
                                                                    placeholder="Key"
                                                                    className='w-100 form-control-modern form-control'
                                                                    value={searchedOrder?.emailSentDate ? new Date(searchedOrder?.emailSentDate).toLocaleString() : ''}
                                                                    readOnly
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <Col lg={1} className='d-flex justify-content-between justify-content-lg-start'>
                                                                <CopyToClipBoardBtn
                                                                    className='w-100 w-lg-auto'
                                                                    inputId={`emailDate`}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group as={Row} className='align-items-center'>
                                                <Col
                                                    as={Form.Label}
                                                    lg={3}
                                                    xl={3}
                                                    sm={12}
                                                    className='control-label text-lg-left mb-lg-0 mb-2 mb-lg-0'
                                                >
                                                    Adresă email CEL:
                                                </Col>
                                                <Col lg={4} xl={4} sm={12} className='mb-2 mb-lg-0'>
                                                    <input
                                                        type='text'
                                                        name='celEmail'
                                                        id={`celEmail`}
                                                        placeholder="Key"
                                                        className='w-100 form-control-modern form-control'
                                                        value={searchedOrder?.order_email ?? ''}
                                                        readOnly
                                                        disabled
                                                    />
                                                </Col>
                                                <Col sm={12} lg={5} xl={5} className='d-flex justify-content-between justify-content-lg-start'>
                                                    <CopyToClipBoardBtn
                                                        className='w-100 w-lg-auto'
                                                        inputId={`celEmail`}
                                                    />
                                                    <Button onClick={handleInstantEmailResend} disabled={isLoadingEmail} className='w-100 ml-1 w-lg-auto btn-success'>
                                                        {isLoadingEmail && !modalSettings.emailModal.isOpen ? (
                                                            <Spinner animation="border" size="sm" role="status" />
                                                        ) : (
                                                            <>
                                                                <i className="fa fa-envelope"></i> Retrimitere pe acelasi email
                                                            </>
                                                        )}
                                                    </Button>
                                                    <Button onClick={handleEmailResend} className='w-100 ml-1 w-lg-auto btn-info'><i className="fa fa-envelope"></i> Retrimitere pe o alta adresa</Button>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className='align-items-center'>
                                                <Col
                                                    as={Form.Label}
                                                    lg={3}
                                                    xl={3}
                                                    className='control-label text-lg-left mb-lg-0'
                                                >
                                                    Licențe achiziționate :
                                                </Col>
                                                <Col lg={8} xl={8}>
                                                    {selectedKeys.length > 0 && (
                                                        <Button
                                                            onClick={(e) => handleResendLicenses()}
                                                            className='w-xs-100 w-lg-auto w-sm-auto mb-2'
                                                        >
                                                            <i className="fa fa-key"></i>
                                                            &nbsp;
                                                            Înlocuiește cele selectate
                                                        </Button>
                                                    )}
                                                    {Object.entries(searchedOrder.purchasedLicenses).map(([type, { products_key }]) => (
                                                        <div key={type} className='mb-4'>
                                                            <h5 className='subtitle mb-2'>{type}</h5>
                                                            {products_key.map(({ id, key_code, name, category }) => (
                                                                <div key={id} className='mb-3'>
                                                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                                                        <div className="d-flex flex-row w-100 align-items-center">
                                                                            <input
                                                                                type='checkbox'
                                                                                checked={!!selectedKeys.find(selected => selected.id === id)}
                                                                                onChange={() => handleCheckboxChange({ id, key_code, name, category })}
                                                                                className='mr-2 checkbox-style-1 p-relative d-block mb-2 mb-sm-0'
                                                                            />
                                                                            <input
                                                                                id={`key_${id}`}
                                                                                type='text'
                                                                                className='form-control-modern form-control mb-2 mb-sm-0'
                                                                                style={{ flex: 1 }}
                                                                                value={key_code.trim()}
                                                                                readOnly
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                        <div className='d-flex flex-column flex-sm-row w-xs-100 w-lg-auto mt-2 mt-sm-0 ml-sm-2'>
                                                                            <CopyToClipBoardBtn
                                                                                className='mb-2 mb-sm-0 w-100 w-sm-auto mr-lg-2'
                                                                                inputId={`key_${id}`}
                                                                            />
                                                                            <Button
                                                                                onClick={() => handleResendLicenses({ id, key_code, name, category })}
                                                                                className='w-100 w-sm-auto'
                                                                            >
                                                                                <i className="fa fa-key"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            {modalSettings.emailModal.isOpen && (
                <Modal isOpen={modalSettings.emailModal.isOpen} onRequestClose={onCloseEmail} style={modalStyles}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Trimitere email
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={12} xl={12}>
                                    <Form.Group as={Row} className='align-items-center'>
                                        <Col
                                            as={Form.Label}
                                            lg={3}
                                            xl={2}
                                            className='control-label text-lg-right mb-lg-0'
                                        >
                                            Email
                                        </Col>
                                        <Col lg={9} xl={10}>
                                            <input
                                                type='text'
                                                name='currentEmail'
                                                id='inputCurrentEmail'
                                                placeholder="Email"
                                                className='w-100 form-control-modern form-control'
                                                defaultValue={''}
                                                onChange={(e) => setModalSettings({ ...modalSettings, emailModal: { ...modalSettings.emailModal, email: e.target.value } })}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col md={12} className="text-right">
                                    <Button
                                        className="modal-confirm mr-2"
                                        variant="danger"
                                        onClick={() => onCloseEmail(false)}
                                    >
                                        Închide
                                    </Button>
                                    <Button
                                        className="modal-confirm mr-2"
                                        variant="success"
                                        onClick={(e) => onCloseEmail(true)}
                                        disabled={isLoadingEmail}
                                    >
                                        {isLoadingEmail ? (
                                            <Spinner animation="border" size="sm" role="status" />
                                        ) : ('Trimite')}

                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Modal>
            )}
            {modalSettings.licensesModal.isOpen && (
                <Modal isOpen={modalSettings.licensesModal.isOpen} onRequestClose={onCloseReplacementLicenses} style={modalStyles}>
                    <Form onSubmit={keyReplacementSubmit}>
                        <Card>
                            <Card.Header>
                                <Card.Title>
                                    Înlocuire licențe
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col lg={12} xl={12}>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={3}
                                                xl={2}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Email CEL
                                            </Col>
                                            <Col lg={3} xl={4}>
                                                <input
                                                    type='email'
                                                    name='currentEmail'
                                                    id='inputCurrentEmailReplacement'
                                                    placeholder="Email"
                                                    className='w-100 form-control-modern form-control'
                                                    defaultValue={modalSettings.licensesModal.email ?? ''}
                                                    readOnly
                                                    required
                                                />
                                            </Col>
                                            <Col
                                                as={Form.Label}
                                                lg={3}
                                                xl={2}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Email Custom
                                            </Col>
                                            <Col lg={3} xl={4}>
                                                <input
                                                    type='email'
                                                    name='customEmail'
                                                    id='inputCustomEmail'
                                                    placeholder="Email"
                                                    className='w-100 form-control-modern form-control'
                                                    defaultValue={''}
                                                    onChange={(e) => {
                                                        setModalSettings({ ...modalSettings, licensesModal: { ...modalSettings.licensesModal, customEmail: e.target.value ?? '' } })
                                                        const originalMailElement = document.getElementById('inputCurrentEmailReplacement')
                                                        if (originalMailElement) {
                                                            if (e.target.value !== '') {
                                                                originalMailElement.style.backgroundColor = '#eb8c89';
                                                                originalMailElement.style.textDecoration = 'line-through';
                                                            } else {
                                                                originalMailElement.style.backgroundColor = '#e9ecef';
                                                                originalMailElement.style.textDecoration = 'none';
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col lg={12} xl={12} className='control-label text-lg-right mb-lg-0'>
                                                <h3 className="d-flex justify-content-start align-items-center text-center mb-2" style={{ gap: '1rem' }}>
                                                    Licențe înlocuitoare:
                                                </h3>
                                            </Col>
                                            <Col lg={12} xl={12} className="align-items-center">
                                                {Object.values(selectedKeys.reduce((acc, key) => {
                                                    // Group keys by category
                                                    const categoryName = key.category.name;
                                                    if (!acc[categoryName]) {
                                                        acc[categoryName] = {
                                                            category: key.category,
                                                            keys: []
                                                        };
                                                    }
                                                    acc[categoryName].keys.push(key.key_code);
                                                    return acc;
                                                }, {})).map((categoryGroup, index) => (
                                                    <div key={index} className="mb-4">
                                                        <h5 className="mb-3">{categoryGroup.category.name}</h5> {/* Category Title */}
                                                        <Row className="d-flex align-items-start">
                                                            <Col lg={6}>
                                                                <textarea
                                                                    name={`keys_category_${categoryGroup.category.name}`}
                                                                    id={`keys_category_${categoryGroup.category.name}`}
                                                                    placeholder={categoryGroup.category.placeholder}
                                                                    className='w-100 form-control-modern form-control mb-2'
                                                                    rows={4}
                                                                    value={categoryGroup.keys.join('\n')} // Display all keys for this category
                                                                    readOnly
                                                                />
                                                                <CopyToClipBoardBtn style={{ width: '100%' }} inputId={`keys_category_${categoryGroup.category.name}`} />
                                                            </Col>
                                                            <Col lg={6}>
                                                                <textarea
                                                                    name={`replacement_keys_category_${categoryGroup.category.name}`}
                                                                    id={`replacement_keys_category_${categoryGroup.category.id}`}
                                                                    placeholder={`Enter replacement keys for ${categoryGroup.category.name}`}
                                                                    className='w-100 form-control-modern form-control'
                                                                    rows={4}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col md={12} className="text-right">
                                        <Button
                                            className="modal-confirm mr-2"
                                            variant="danger"
                                            onClick={() => onCloseReplacementLicenses(false)}
                                        >
                                            Închide
                                        </Button>
                                        <Button
                                            className="modal-confirm mr-2"
                                            variant="success"
                                            type="submit"
                                            disabled={isLoadingEmail}
                                        >
                                            {isLoadingEmail ? (
                                                <Spinner animation="border" size="sm" role="status" />
                                            ) : ('Trimite')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default OrderSearch;