import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';
import { Link } from 'react-router-dom';
import { getWhitelistLogs } from '../../../api/whitelist-logs';

export default function WhitelistLogsList() {
    const [loading, setLoading] = useState(true);
    const [ajax, setAjax] = useState({
        data: [],
        total: 0,
    });
    const [search, setSearch] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [tableRef, setTableRef] = useState(null);
    const [selected, setSelected] = useState([]);
    const [bulk, setBulk] = useState('');
    const [totalCost, setTotalCost] = useState(0);

    const columns = [
        {
            Header: 'Email',
            accessor: 'activationWhitelist',
            sortable: true,
            Cell: (row) => (
                <>
                    <Link
                        to={`${process.env.PUBLIC_URL}/dashboard/whitelist/${row.value.id}`}
                        className='on-default edit-row mr-2'
                    >
                        {row.value.email}
                    </Link>
                </>
            )
        },
        {
            Header: 'Cost',
            accessor: 'cost',
            sortable: true,
        },
        {
            Header: 'Creat La',
            accessor: 'createdAt',
            sortable: true,
            Cell: ({ value }) => (new Date(value).toLocaleString()),
        },
    ];

    useEffect(() => {
        setSelected((selected) =>
            selected.map((item) => {
                return {
                    ...item,
                    selected: selectAll,
                };
            })
        );
        document.querySelector('body').classList.add('loaded');
    }, [selectAll]);

    function isSelected(key) {
        return selected.find((item) => item.id === key && item.selected);
    }

    function onSelectChange(e, value, row) {
        setSelected(
            selected.map((item) => {
                if (item.id === row.id) {
                    return {
                        ...item,
                        selected: !item.selected,
                    };
                }
                return item;
            })
        );
    }

    async function bulkAction(e) {
        e.preventDefault();
        if (!bulk) {
            return toast(
                <PNotify
                    title='Warning'
                    icon='fas fa-exclamation'
                    text='Please choose one of actions.'
                />,
                {
                    containerId: 'default',
                    className: 'notification-warning',
                }
            );
        }
    }

    function fetchData(state) {
        let filtered = [...state.filtered];
        setLoading(true);
        getWhitelistLogs(
            state.page + 1,
            state.pageSize,
            filtered,
            state.sorted
        ).then((results) => {
            if (results?.data) {
                setAjax({
                    data: results.data,
                    total:
                        parseInt(results.total / state.pageSize) +
                        !!(results.total % state.pageSize),
                    totalRecords: results.totalResults,
                    totalFilteredRecords: results.total,
                    isFiltered: filtered.length ? true : false,
                });
                setTotalCost(results.totalCost);
                setSelected(
                    results.data.map((media) => {
                        return {
                            id: media.id,
                            selected: false,
                        };
                    })
                );
                setSelectAll(false);
            }
            setLoading(false);
        });

    }

    function searchWhitelistLogs(e) {
        e.preventDefault();
        tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
    }

    return (
        <>
            <Breadcrumb
                current={'Conturi Activare Logs'}
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'Settings',
                        url: '#',
                    },
                    {
                        name: 'Activity Logs',
                        url: '#',
                    },
                    {
                        name: 'Listează Conturi Activare Logs',
                        url: '/dashboard/whitelist-logs',
                    },
                ]}
            />

            <Row>
                <Col>
                    <Form method='GET' action='#' onSubmit={searchWhitelistLogs}>
                        <Card className='card-modern'>
                            <Card.Body>
                                <div className='datatables-header-footer-wrapper'>
                                    <div className='datatable-header'>
                                        <Row className='align-items-lg-center mb-3'>
                                            <Col lg={4} xl={2}>
                                                <div className='d-flex align-items-lg-center flex-wrap'>
                                                    <Form.Label className='d-none d-xl-block ws-nowrap mr-3 mb-0'>
                                                        Total cost:
                                                    </Form.Label>
                                                    <Form.Control
                                                        as='input'
                                                        className='form-control-modern disabled'
                                                        value={totalCost !== undefined ? totalCost : 0}
                                                        disabled={true}
                                                    >
                                                    </Form.Control>
                                                </div>
                                            </Col>
                                            <Col className='col-auto pl-lg-1 ml-auto'>
                                                <div className='search search-style-1 mx-lg-auto w-auto'>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type='text'
                                                            className='search-term'
                                                            placeholder='Search'
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                        />
                                                        <InputGroup.Append>
                                                            <Button variant='default' type='submit'>
                                                                <i className='bx bx-search'></i>
                                                            </Button>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <PtTable
                                        className='table table-core-simple -striped mb-0'
                                        data={ajax.data}
                                        loading={loading}
                                        columns={columns}
                                        pages={ajax.total}
                                        pageSize={12}
                                        manual
                                        onFetchData={fetchData}
                                        selectAll={selectAll}
                                        toggleAll={() => setSelectAll(!selectAll)}
                                        isSelected={(key) => isSelected(key)}
                                        toggleSelection={onSelectChange}
                                        onChangeRef={(ref) => setTableRef(ref)}
                                        totalRecords={ajax.totalRecords}
                                        totalFilteredRecords={ajax.totalFilteredRecords}
                                        isFiltered={ajax.isFiltered}
                                    />

                                    <div className='datatable-footer'>
                                        <Row className='align-items-center justify-content-between mt-3'>
                                            <Col md='auto' className='mb-3 mb-lg-0'>
                                                <div className='d-flex'>
                                                    <Form.Control
                                                        as='select'
                                                        className='select-style-1 bulk-action w-auto mr-3'
                                                        value={bulk}
                                                        onChange={(e) => setBulk(e.target.value)}
                                                        style={{ minWidth: '120px' }}
                                                    >
                                                        <option value=''>Bulk Actions</option>
                                                    </Form.Control>
                                                    <Button
                                                        href='#bulk-action'
                                                        className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                                                        variant='light'
                                                        onClick={bulkAction}
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg='auto' className='mb-3 mb-lg-0'>
                                                <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </>
    );

}