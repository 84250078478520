import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { check, login } from '../../../api/auth';
import { withDismissible } from '../../hoc/index';
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignIn() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [recaptcha, setRecaptcha] = useState('');
  const AlertDismissible = withDismissible(Alert);

  const siteKey = process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD
    : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_DEV

  const navigate = useNavigate();

  useEffect(() => {
    async function checkJWT() {
      const result = await check();
      if (result.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        localStorage.setItem('isLoggedIn', 'false');
      }

    }
    if (localStorage.getItem('isLoggedIn') === 'true') {
      checkJWT();
    }
    document.querySelector('body').classList.add('loaded');
  }, [navigate]);

  const handleRecaptchaChange = (value) => {
    setRecaptcha(value);
  }

  async function handleSubmit(event) {
    event.persist();
    setShow(false);
    setMessage('');
    event.preventDefault();

    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
      remember: event.target.remember.checked,
      token: recaptcha,
    };
    const result = await login(data);
    if (result.status === 200 && result.data.error !== 1) {
      setShow(false);
      setMessage('');
      const result = await check(data);
      if (result.status === 200) {
        localStorage.setItem('isLoggedIn', true);
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
        navigate(0);
      } else {
        setMessage(result.data.message);
        setShow(true);
      }
    } else {
      setMessage(result.data.message ?? result.data.error + ": Please try again!");
      setShow(true);
    }
  }

  return (
    <section className='body-sign'>
      <div className='center-sign'>
        {show && message && (
          <AlertDismissible variant='danger'>{message}</AlertDismissible>
        )}
        <Card className='card-sign'>
          <Card.Body>
            <h2 className='sign-title'>Sign In</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='form-custom-group'>
                <Form.Label>
                  Email Address <span className='required'>*</span>
                </Form.Label>
                <Form.Control name='email' type='email' required autoFocus />
              </Form.Group>

              <Form.Group className='form-custom-group'>
                <Form.Label className='float-left'>
                  Password <span className='required'>*</span>
                </Form.Label>
                <Form.Control name='password' type='password' required />
              </Form.Group>

              <Row className='rem-password'>
                <Col sm={8}>
                  <Form.Check
                    name='remember'
                    className='checkbox-default'
                    label='Remember me'
                  />
                </Col>

                <Col sm={4}>
                  <Link to='/forgot-password'>Forgot Password?</Link>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <ReCAPTCHA
                    sitekey={siteKey}
                    style={{ marginBottom: '2rem' }}
                    onChange={handleRecaptchaChange}
                  />
                </Col>
              </Row>

              <Button type='submit' className='btn-login'>
                Sign In
              </Button>
            </Form>
          </Card.Body>
        </Card>

        <p className='text-center text-muted my-3'>
          &copy; Copyright 2021. All Rights Reserved.
        </p>
      </div>
    </section>
  );
}
