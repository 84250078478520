import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import Modal from "react-modal";
import { formatDateTime } from '../../../../utils';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '600px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#app');

export default function keyStorageHistoryModal({ isOpen, onClose, details }) {
    const { replacementHistory, replacedKey } = details;
    let keyValues = (
        <tr>
            <td>
                No history
            </td>
        </tr>
    );
    let originalKey = '';

    function closeModal(result = false) {
        onClose(result);
    }

    if (issetArray(replacementHistory)) {
        originalKey = (
            <tr>
                <td>
                    <b>Original Key:</b> {details.key}
                </td>
            </tr>
        );
        keyValues = Object.entries(replacementHistory).map(([key, value]) => (
            <>
                <hr />
                <tr key={++key}>
                    <td><b>Replacement {++key}:&nbsp;</b>{value.originalKeyStorage.key}</td>
                </tr>
                <tr>
                    <td><b>Replaced at :&nbsp;</b>{ formatDateTime(new Date(value.created_at))}</td>
                </tr>
            </>
        ));
    } else if (isset(replacedKey)) {
        keyValues = (
            <>
                <tr key={replacedKey.id}>
                    <td><b>Replacing :&nbsp;</b>{replacedKey.replacementKeyStorage.key}</td>
                </tr>
                <tr>
                    <td><b>Replaced at :&nbsp;</b>{formatDateTime(new Date(replacedKey.created_at))}</td>
                </tr>
            </>
        )
    }

    function issetArray(object) {
        return (Array.isArray(object) && object.length > 0 && typeof (object[0]) !== 'undefined');
    }

    function isset(object) {
        return (typeof object !== 'undefined' && object);
    }

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyles}
            onRequestClose={closeModal}
        >
            <Card>
                <Card.Header>
                    <Card.Title>Replacement Keys History:</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="modal-wrapper d-flex justify-content-center">
                        <table>
                            <tbody>
                                {originalKey}
                                {keyValues}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={() => closeModal(false)}
                            >Ok</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}
