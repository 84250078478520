import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { resetPassword, sendResetToken } from '../../../api/auth';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { withDismissible } from '../../hoc';

export default function ResetPassword() {
    const [passwordReset, setPasswordReset] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const AlertDismissible = withDismissible(Alert);

    useEffect(() => {
        document.querySelector('body').classList.add('loaded');
    }, []);

    async function resetPass(e) {
        e.preventDefault();
        const password = e.target.password.value;
        const data = {
            password,
            token,
        }
        const result = await resetPassword(data);
        if (result.status === 200 && result.data.error === 0) {
            setPasswordReset(true);
        }
        setShow(true);
        setMessage(result.data.message);
    }

    return (
        <section className='body-sign'>
            <div className='center-sign'>
                {show && message && (
                    <AlertDismissible variant='danger'>{message}</AlertDismissible>
                )}
                <Card className='card-sign'>
                    <Card.Body>
                        {!passwordReset ? (
                            <>
                                <h2 className='sign-title'>Resetare Parola</h2>
                                <Form onSubmit={resetPass}>
                                    <Form.Group className='form-custom-group mb-0'>
                                        <InputGroup>
                                            <Form.Control name='password' type='password' required placeholder='********' />
                                            <Button type='submit' variant='primary'>
                                                Reset password
                                            </Button>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>
                            </>
                        ) : (
                            <>
                                <h2 className='sign-title'>Resetare parola</h2>
                                <div>
                                    <p>Parola a fost resetata cu succes</p>
                                    <p className='text-center mt-3'>
                                        <Link to='/'>Sign In!</Link>
                                    </p>
                                </div>
                            </>
                        )}
                    </Card.Body>
                </Card>

                <p className='text-center text-muted my-3'>
                    &copy; Copyright 2021. All Rights Reserved.
                </p>
            </div>
        </section>
    );
}
