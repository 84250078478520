import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getListApi(setProductCode) {
  const result = await getApi();
  if (result.status === 200) {
    setProductCode(result.data.message);
  }
}

export async function getFiltered(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/error-orders`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let results = response.data.message;
      let totalResults = response.data.message.length;
      filters &&
        filters.forEach((filter) => {
          results = results.filter((product) => {
            if(filter.id === 'dates'){
              return (
                (product.created_at && filter.id === 'dates' &&
                (new Date(product.created_at).setHours(0,0,0,0)) >= (new Date(filter.value[0]).setHours(0,0,0,0)) &&
                (new Date(product.created_at).setHours(0,0,0,0)) <= (new Date(filter.value[1])).setHours(0,0,0,0))
              );
            }
            return (
              (product.id && product.id.toString().search(new RegExp(filter.value, 'i')) >= 0) ||
              (product.error_message && product.error_message.search(new RegExp(filter.value, 'i')) >= 0) ||
              (product.orderId && product.orderId.toString().search(new RegExp(filter.value, 'i')) >= 0))
          });
        });

      sortBy &&
        sortBy.forEach((sort) => {
          let index = sort.desc ? -1 : 1;
          switch (sort.id) {
            case 'id':
              results = results.sort((a, b) =>
                a.id < b.id ? -index : index
              );
            break;
            case 'error_message':
              results = results.sort((a, b) =>
                a.error_message < b.error_message ? -index : index
              );
            break;
            case 'orderId':
              results = results.sort((a, b) =>
                a.orderId < b.orderId ? -index : index
              );
            break;
            case 'created_at':
              results = results.sort((a, b) =>
                a.created_at < b.created_at ? -index : index
              );
            break;
            default:
            break;
          }
        });

      return {
        data: results.slice(from, to),
        total: results.length,
        totalResults: totalResults,
      };
    })
    .catch((error) => console.error(error));
}

export async function createApi(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/data/error-orders`,
    withCredentials: true,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/data/error-orders/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/data/error-orders/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteByDatesApi(startDate, endDate) {
  endDate.setDate(endDate.getDate() + 1);
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];
  const config = {
    method: 'delete',
    url: `${API_URL}/data/error-orders-bulk/?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getApi() {
  const config = {
    method: 'get',
    url: `${API_URL}/data/error-orders`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getApiById(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/error-orders/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}