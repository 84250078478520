import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getSuppliersApi() {
  const result = await getSuppliers();
  if (result.status === 200) {
    return result;
  }
}

export async function getFilteredSuppliers(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/suppliers`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let error = response.data.error
      if (!error) {
        let results = response.data.message;
        let totalResults = response.data.message.length;
        filters &&
          filters.forEach((filter) => {
            results = results.filter((product) => {
              const isPrimary = product.is_primary ? 'Da' : 'Nu';
              return (
                (product.company_name && product.company_name.search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.vat.toString() && product.vat.toString().search(new RegExp(filter.value, 'i')) >= 0) ||
                (product.alias && product.alias.search(new RegExp(filter.value, 'i')) >= 0) ||
                (isPrimary && isPrimary.search(new RegExp(filter.value, 'i')) >= 0)
              )
            });
          });

        sortBy &&
          sortBy.forEach((sort) => {
            let index = sort.desc ? -1 : 1;
            switch (sort.id) {
              case 'company_name':
                results = results.sort((a, b) =>
                  a.company_name < b.company_name ? -index : index
                );
                break;
              case 'vat':
                results = results.sort((a, b) =>
                  a.vat < b.vat ? -index : index
                );
                break;
              case 'alias':
                results = results.sort((a, b) =>
                  a.alias < b.alias ? -index : index
                );
                break;
              default:
                break;
            }
          });

        return {
          data: results.slice(from, to),
          total: results.length,
          totalResults: totalResults,
        };
      }
    })
    .catch((error) => console.error(error));
}

export async function createSupplierApi(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/suppliers`,
    withCredentials: true,
    data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateSupplierApi(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/suppliers/${id}`,
    withCredentials: true,
    data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteSuppliersApi(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/suppliers/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function getSuppliers() {
  const config = {
    method: 'get',
    url: `${API_URL}/suppliers`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}

export async function getSupplier(id) {
  const config = {
    method: 'get',
    url: `${API_URL}/suppliers/${id}`,
    withCredentials: true,
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(error));
}