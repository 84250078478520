import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import Breadcrumb from '../common/breadcrumb';
import GeneratorControlPanel from '../../features/dashboard-widgets/generator-control-panel';
import LogViewer from '../../features/dashboard-widgets/log-viewer';
import OrderSearch from '../../features/dashboard-widgets/order-search';
export default function Dashboard({ user }) {


  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);

  return (
    <>
      <Breadcrumb current='Dashboard' />
      <Row>
        <Col xs={12} xl={12} xxl={12}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={4} className='col-4'>
              <Card>
                <Card.Body className='p-0'>
                  <GeneratorControlPanel user={user} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} xl={8} xxl={8} className="pt-2 pt-xl-0 mt-4 mt-xl-0">
              <Row className="h-100">
                <Col>
                  <Card className="card-modern h-100">
                    <Card.Header>
                      <Card.Title>Logs</Card.Title>
                    </Card.Header>
                    <Card.Body className="h-100 pt-2">
                      <LogViewer />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="card-modern h-100">
            <Card.Header>
              <Card.Title>Caută comandă</Card.Title>
            </Card.Header>
            <Card.Body className="h-100 pt-2">
              <OrderSearch user={user} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
