import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getFilteredUsers(from = 0, to, filters, sortBy) {
  const config = {
    method: 'get',
    url: `${API_URL}/data/users`,
    withCredentials: true,
  };
  return axios(config)
    .then((response) => {
      let results = response.data.message;
      let totalResults = response.data.message.length;
      filters &&
        filters.forEach((filter) => {
          results = results.filter((product) => {
            if (filter.id !== 'name'){
                return product[filter.id] === parseInt(filter.value);
            }
            return (
              (product.first_name + ' ' + product.last_name).search(
                new RegExp(filter.value, 'i')
              ) >= 0 ||
              product['email'].search(new RegExp(filter.value, 'i')) >= 0
            );
          });
        });

      sortBy &&
        sortBy.forEach((sort) => {
          let index = sort.desc ? -1 : 1;
          switch (sort.id) {
            case 'email':
              results = results.sort((a, b) =>
                a.email < b.email ? -index : index
              );
              break;
            case 'role':
              results = results.sort((a, b) =>
                a.role.name < b.role.name ? -index : index
              );
              break;
            default:
              break;
          }
        });

      return {
        data: results.slice(from, to),
        total: results.length,
        totalResults: totalResults,
      };
    })
    .catch((error) => console.error(error));
}

export async function createAccount(data) {
  const config = {
    method: 'post',
    url: `${API_URL}/data/users`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function updateAccount(data, id) {
  const config = {
    method: 'patch',
    url: `${API_URL}/data/users/${id}`,
    withCredentials: true,
    data: data,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function deleteAccount(id) {
  const config = {
    method: 'delete',
    url: `${API_URL}/data/users/${id}`,
    withCredentials: true,

  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
