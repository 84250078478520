import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { toast } from 'react-toastify';
import { createRole } from '../../../api/role';
import PNotify from '../../../features/elements/p-notify';


export default function RoleCreate() {
  const navigate = useNavigate();
  async function saveRole(event) {
    event.preventDefault();

    let data = {
      name: event.target.name.value
    };
    const result = await createRole(data);
    if (result.status === 200 && result.data.error === 0) {
      document.getElementById('myForm').reset();
      return toast(
        <PNotify
          title='Success'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-success',
        }
      );
    } else {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={result.data.message}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
  }

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  });

  return (
    <>
      <Breadcrumb
        current='Add Roles'
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Roles',
            url: '/dashboard/roles',
          },
          {
            name: 'New Role',
            url: '/dashboard/roles/create',
          },
        ]}
      />

      <Form
        className='core-form form'
        action='#'
        method='post'
        onSubmit={saveRole}
        id='myForm'
      >
        <Row>
          <Col>
            <Card className='card-modern card-big-info'>
              <Card.Body>
                <Row>
                  <Col lg='2-5' xl='1-5'>
                    <i className='card-big-info-icon bx bxs-user-badge'></i>
                    <h2 className='card-big-info-title'>Role Info</h2>
                    <p className='card-big-info-desc'>
                      Add here the role info.
                    </p>
                  </Col>
                  <Col lg='3-5' xl='4-5'>
                    <Form.Group as={Row} className='align-items-center'>
                      <Col
                        as={Form.Label}
                        lg={5}
                        xl={3}
                        className='control-label text-lg-right mb-lg-0'
                      >
                        Name
                      </Col>
                      <Col lg={7} xl={6}>
                        <Form.Control
                          type='text'
                          maxLength='64'
                          className='form-control-modern'
                          name='name'
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='action-buttons'>
          <Col md='auto' className='col-12'>
            <Button
              type='submit'
              className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
              variant='primary'
            >
              <i className='bx bx-save text-4 mr-2'></i>Salvează Rol
            </Button>
          </Col>
          <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
            <Button
              onClick={() => navigate(-1)}
              className="btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center"
              variant="light"
            >
              Înapoi
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
