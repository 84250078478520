import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';

import { deleteKeyStorageApi, getFilteredDataForExport, getFilteredKeyStorage } from '../../../api/key-storage';
import { getStatusesApi } from '../../../api/status';
import UserContext from '../../../utils/context/userContext';
import { roles } from '../../../utils/data/role';
import KeyStorageHistoryModal from './modals/key-storage-history-modal';
import KeyReplacementModal from './modals/key-replacement-modal';
import * as XLSX from 'xlsx';
import { format } from 'rsuite/esm/utils/dateUtils';
import { getListApi } from '../../../api/category';

export default function KeyStorageList() {
  const [loading, setLoading] = useState(true);
  const [ajax, setAjax] = useState({
    data: [],
    total: 0,
  });

  // Filter Variables
  const [search, setSearch] = useState('');

  const [selectAll, setSelectAll] = useState(false);
  const [tableRef, setTableRef] = useState(null);
  const [selected, setSelected] = useState([]);
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
  const [isOpenReplacementModal, setIsOpenReplacementModal] = useState(false);
  const [keyStorage, setKeyStorage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [bulk, setBulk] = useState('');
  const [filters, setFilters] = useState([]);
  const tableName = 'key_storage';
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState('');

  const loggedUser = useContext(UserContext)

  // Columns
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      sortable: true,
    },
    {
      Header: 'Cheie',
      accessor: 'key',
      sortable: true,
    },
    {
      Header: 'Categorie',
      accessor: 'catLicenseCatModule',
      sortable: true,
      Cell: (row) => (
        <>
          {loggedUser.level === roles.admin ?
            <Link
              to={`${process.env.PUBLIC_URL}/dashboard/category/${row.value.id}`}
              className='on-default edit-row mr-2'
            >
              {row?.value?.name ?? '-'}
            </Link>
            : (row?.value?.name ?? '-')}
        </>
      )
    },
    {
      Header: 'Supplier',
      id: 'supplier',
      sortable: true,
      accessor: (d) => d?.supplier?.company_name || '-'
    },
    {
      Header: 'Factura',
      id: 'invoice',
      sortable: true,
      accessor: (d) => d?.invoice?.invoice_name ? d.invoice.invoice_name : '-'
    },
    {
      Header: 'Sursa',
      accessor: 'key_origin',
      sortable: true,
    },
    {
      Header: 'Replacement Data',
      id: 'date_added',
      sortable: true,
      accessor: (d) => d?.date_added ? new Date(d.date_added).toLocaleDateString() : '-'
    },
    {
      Header: 'Inlocuiri',
      id: 'replacement',
      accessor: (d) => d,
      sortable: true,
      Cell: (row) => (
        <>
          <Button className='on-default edit-row mr-2' onClick={() => openHistoryModal(row.value)}>
            <i className='fas fa-eye'></i> {row.value.replacement}
          </Button>
        </>
      ),
    },
    {
      Header: 'Status',
      id: 'status',
      sortable: true,
      accessor: (d) => d?.status?.name || '-'
    },
    {

      Header: 'Acțiuni',
      id: 'action',
      accessor: (d) => d,
      className: 'actions',
      headerClassName: 'justify-content-center',
      width: 100,
      Cell: (row) => (
        <>
          <a href='#replace' className='on-default edit-row mr-2' onClick={(e) => openReplaceModal(e, row.value)}>
            <i className='fas fa-exchange-alt'></i>
          </a>
          <a
            href='#del'
            className='on-default remove-row'
            onClick={(e) => deleteRow(e, row.value.id)}
          >
            <i className='far fa-trash-alt'></i>
          </a>
        </>
      ),
    },
  ];

  useEffect(() => {
    setSelected((selected) =>
      selected.map((item) => {
        return {
          ...item,
          selected: selectAll,
        };
      })
    );

    async function getStatuses() {
      const result = await getStatusesApi(tableName);
      if (result.status === 200) {
        setStatuses(result.data.message);
      }
    }
    if (statuses.length === 0) {
      getStatuses();
    }

    async function getCategories() {
      const result = await getListApi();
      if (result.status === 200) {
        setCategories(result.data.message);
      }
    }

    if (categories.length === 0) {
      getCategories();
    }

    document.querySelector('body').classList.add('loaded');
  }, [selectAll, statuses.length, keyStorage, categories]);

  function isSelected(key) {
    return selected.find((item) => item.id === key && item.selected);
  }

  function onSelectChange(e, value, row) {
    setSelected(
      selected.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      })
    );
  }

  async function deleteRow(e, index) {
    e.preventDefault();
    if (window.confirm('Ești sigur că vrei să ștergi aceste date?')) {

      const deleteItem = await deleteKeyStorageApi(index);
      if (deleteItem.status === 200) {
        setAjax({
          ...ajax,
          data: ajax.data.filter((item) => item.id !== index),
        });
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={deleteItem.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={deleteItem.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  async function bulkAction(e) {
    e.preventDefault();
    if (!bulk) {
      return toast(
        <PNotify
          title='Warning'
          icon='fas fa-exclamation'
          text='Please choose one of actions.'
        />,
        {
          containerId: 'default',
          className: 'notification-warning',
        }
      );
    }
    if (bulk === 'delete') {
      if (!selected.find((item) => item.selected)) {
        return toast(
          <PNotify
            title='Warning'
            icon='fas fa-exclamation'
            text='Choose at least one item.'
          />,
          {
            containerId: 'default',
            className: 'notification-warning',
          }
        );
      }

      let stringId = '';
      const bulkDeleteId = selected.filter((item) => item.selected);
      var bulkLength = bulkDeleteId.length;
      bulkDeleteId.forEach((item, index) => {
        if (index + 1 === bulkLength) {
          stringId += item.id;
        } else {
          stringId += item.id + '&';
        }
      });

      const deleteItems = await deleteKeyStorageApi(stringId);
      if (deleteItems.status === 200) {
        setAjax({
          ...ajax,
          data: ajax.data.filter((media) =>
            selected.find((item) => item.id === media.id && !item.selected)
          ),
        });
        return toast(
          <PNotify
            title='Success'
            icon='fas fa-check'
            text={deleteItems.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-success',
          }
        );
      } else {
        return toast(
          <PNotify
            title='Error'
            icon='fas fa-check'
            text={deleteItems.data.message}
          />,
          {
            containerId: 'default',
            className: 'notification-danger',
          }
        );
      }
    }
  }

  async function exportToExcel() {
    const rows = await getExportData();
    if (!rows) { return }
    let objectMaxLength = await getExcelColumnWidths(rows);
    const wcols = objectMaxLength.map((value) => ({ wch: value })); //wch - width based on character
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);
    worksheet['!cols'] = wcols; // Add column widths

    XLSX.utils.sheet_add_aoa(worksheet, [
      ["Key", "Category", "Supplier", "Invoice", "Replacement", "Status"],
    ], { origin: 0 }) // Add table headers

    XLSX.utils.book_append_sheet(workbook, worksheet, "Keys");

    XLSX.writeFile(workbook, `Invalid_Keys_${format(new Date(), 'dd_mm_yyyy')}.xlsx`, { type: 'buffer', bookType: 'xlsx' });
  }

  async function getExportData() {
    const keyStorage = await getFilteredDataForExport(filters);
    if (!keyStorage.total) {
      return toast(
        <PNotify
          title='Error'
          icon='fas fa-check'
          text={'No data to export!'}
        />,
        {
          containerId: 'default',
          className: 'notification-danger',
        }
      );
    }
    const exportData = keyStorage.data.reduce((acc, obj) => {
      acc.push({
        key: obj.key,
        category: obj.catLicenseCatModule.name,
        supplier: obj.supplier.company_name,
        invoice: obj.invoice.invoice_name,
        replacement: obj.replacement,
        status: obj.status.name,
      })
      return acc;
    }, []);
    return exportData;
  }

  async function getExcelColumnWidths(rows) {
    // TODO DELETE THIS IF APPLICATION TOO SLOW AND ADD FIXED WIDTH VALUE
    let objectMaxLength = [];
    for (let i = 0; i < rows.length; i++) {
      let value = Object.values(rows[i]);
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 10;
        } else {
          objectMaxLength[j] =
            objectMaxLength[j] >= value[j].length
              ? objectMaxLength[j]
              : value[j].length;
        }
      }
    }
    //
    return objectMaxLength;
  }

  function searchTable(e) {
    e.preventDefault();
    tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
  }

  function fetchData(state) {
    let filtered = [...state.filtered];
    status.length && filtered.push({ id: 'status_id', value: status });
    categories.length && filtered.push({ id: 'category_id', value: category });
    setFilters(filtered);
    setLoading(true);
    getFilteredKeyStorage(
      state.page * state.pageSize,
      (state.page + 1) * state.pageSize,
      filtered,
      state.sorted
    ).then((results) => {
      if (results?.data) {
        setAjax({
          data: results.data,
          total:
            parseInt(results.total / state.pageSize) +
            !!(results.total % state.pageSize),
          totalRecords: results.totalResults,
          totalFilteredRecords: results.total,
          isFiltered: filtered.length ? true : false,
        });
        setSelected(
          results.data.map((media) => {
            return {
              id: media.id,
              selected: false,
            };
          })
        );
        setSelectAll(false);
      }
      setLoading(false);
    });
  }

  function openHistoryModal(keyStorage) {
    setKeyStorage(keyStorage);
    setIsOpenHistoryModal(true);
  }

  function openReplaceModal(e, keyToReplace) {
    e.preventDefault();
    setKeyStorage(keyToReplace);
    setIsOpenReplacementModal(true);
  }

  return (
    <>
      <Breadcrumb
        current={'Key Storage'}
        paths={[
          {
            name: 'Dashboard',
            url: '/dashboard',
          },
          {
            name: 'Gestionare Chei',
            url: '#',
          },
          {
            name: 'Key Storage',
            url: '#',
          },
          {
            name: 'Listează Key Storage',
            url: '/dashboard/key-storage',
          },
        ]}
      />

      <Row>
        <Col>
          <Form method='GET' action='#' onSubmit={searchTable}>
            <Card className='card-modern'>
              <Card.Body>
                <div className='datatables-header-footer-wrapper'>
                  <div className='datatable-header'>
                    <Row className='align-items-lg-center mb-3'>
                      <Col xl='auto' className='mb-2 mt-1 mb-xl-0'>
                        <Button
                          as={Link}
                          to={`${process.env.PUBLIC_URL}/dashboard/key-storage/create`}
                          className='font-weight-semibold'
                          variant='primary'
                          size='md'
                        >
                          + Adaugă key storage
                        </Button>
                      </Col>
                      <Col xl='auto' className='mb-2 mt-1 mb-xl-0'>
                        <Button
                          className='font-weight-semibold'
                          variant='warning'
                          size='md'
                          onClick={exportToExcel}
                        >
                          <i className='fas fa-file-excel'></i> Export
                        </Button>
                      </Col>
                      <Col
                        lg='auto'
                        className='mb-2 mb-lg-0 ml-xl-auto pl-xl-1'
                      >
                        <Row className='d-flex flex-wrap'>
                          <Col className='d-flex flex-col justify-center align-items-center'>
                            <Form.Label className='d-none d-xl-block ws-nowrap mr-3 ml-3 mb-0'>
                              Filter Category:
                            </Form.Label>
                            <Form.Control
                              as='select'
                              className='select-style-1 filter-by w-auto my-1 mr-2'
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            >
                              <option value=''>All</option>
                              {categories
                                ? categories.map((data) => {
                                  return (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  );
                                })
                                : ''}
                            </Form.Control>
                            <Button
                              type='submit'
                              className='filter-btn my-1'
                              variant='primary'
                            >
                              Filter
                            </Button>
                          </Col>
                          <Col className='d-flex flex-col justify-center align-items-center'>
                            <Form.Label className='d-none d-xl-block ws-nowrap mr-3 ml-3 mb-0'>
                              Filter Status:
                            </Form.Label>
                            <Form.Control
                              as='select'
                              className='select-style-1 filter-by w-auto my-1 mr-2'
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value=''>All</option>
                              {statuses
                                ? statuses.map((data) => {
                                  return (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  );
                                })
                                : ''}
                            </Form.Control>
                            <Button
                              type='submit'
                              className='filter-btn my-1'
                              variant='primary'
                            >
                              Filter
                            </Button>
                          </Col>
                        </Row>
                      </Col>

                      <Col className='col-auto pl-lg-2 flex-no-wrap'>
                        <div className='search search-style-1 mx-lg-auto w-auto'>
                          <InputGroup>
                            <Form.Control
                              type='text'
                              className='search-term'
                              placeholder='Caută'
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <InputGroup.Append>
                              <Button variant='default' type='submit'>
                                <i className='bx bx-search'></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </Col>

                    </Row>
                  </div>

                  <PtTable
                    className='table table-core-simple -striped mb-0'
                    data={ajax.data}
                    loading={loading}
                    columns={columns}
                    pages={ajax.total}
                    pageSize={12}
                    manual
                    onFetchData={fetchData}
                    selectAll={selectAll}
                    toggleAll={() => setSelectAll(!selectAll)}
                    isSelected={(key) => isSelected(key)}
                    toggleSelection={onSelectChange}
                    onChangeRef={(ref) => setTableRef(ref)}
                    totalRecords={ajax.totalRecords}
                    totalFilteredRecords={ajax.totalFilteredRecords}
                    isFiltered={ajax.isFiltered}
                  />

                  <div className='datatable-footer'>
                    <Row className='align-items-center justify-content-between mt-3'>
                      <Col md='auto' className='mb-3 mb-lg-0'>
                        <div className='d-flex'>
                          <Form.Control
                            as='select'
                            className='select-style-1 bulk-action w-auto mr-3'
                            value={bulk}
                            onChange={(e) => setBulk(e.target.value)}
                            style={{ minWidth: '120px' }}
                          >
                            <option value=''>Acțiuni în masă</option>
                            <option value='delete'>Șterge</option>
                          </Form.Control>
                          <Button
                            href='#bulk-action'
                            className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                            variant='light'
                            onClick={bulkAction}
                          >
                            Aplică
                          </Button>
                        </div>
                      </Col>
                      <Col lg='auto' className='mb-3 mb-lg-0'>
                        <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      </Row >
      {keyStorage && isOpenHistoryModal && (
        <KeyStorageHistoryModal
          isOpen={isOpenHistoryModal}
          details={keyStorage}
          onClose={() => {
            setIsOpenHistoryModal(false)
            setKeyStorage(null)
          }}
        />
      )
      }
      {
        keyStorage && isOpenReplacementModal && (
          <>
            <KeyReplacementModal
              isOpen={isOpenReplacementModal}
              details={keyStorage}
              onClose={() => {
                setIsOpenReplacementModal(false)
                setKeyStorage(null)
              }}
            />
          </>
        )
      }
    </>
  );
}
