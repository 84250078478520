import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getFailedActivationLogsWithFilter(from = 0, to, filters, sortBy) {
    const config = {
        method: 'get',
        url: `${API_URL}/failed-activation-logs`,
        withCredentials: true,
    };
    return axios(config)
        .then((response) => {
            let error = response.data.error
            if (!error) {
                let results = response.data.message;
                let totalResults = response.data.message.length;
                filters &&
                    filters.forEach((filter) => {
                        results = results.filter((failedActivationLog) => {
                            const accountDetailsValue = failedActivationLog.accountDetails?.email || failedActivationLog.accountDetails?.orderId || '-';
                            return (
                                failedActivationLog['iid'].search(new RegExp(filter.value, 'i')) >= 0 ||
                                failedActivationLog['errorMessage'].search(new RegExp(filter.value, 'i')) >= 0 ||
                                accountDetailsValue.search(new RegExp(filter.value, 'i')) >= 0 ||
                                failedActivationLog['createdAt'].search(new RegExp(filter.value, 'i')) >= 0
                            );
                        });
                    });

                sortBy &&
                    sortBy.forEach((sort) => {
                        let index = sort.desc ? -1 : 1;
                        switch (sort.id) {
                            case 'iid':
                                results = results.sort((a, b) =>
                                    a.eventType < b.eventType ? -index : index
                                );
                                break;
                            case 'errorMessage':
                                results = results.sort((a, b) =>
                                    a.route < b.route ? -index : index
                                );
                                break;
                            case 'accountDetails':
                                results = results.sort((a, b) => {
                                    const aValue = a.accountDetails?.email || a.accountDetails?.orderId || '-';
                                    const bValue = b.accountDetails?.email || b.accountDetails?.orderId || '-';

                                    return aValue < bValue ? -index : index;
                                });
                                break;
                            case 'createdAt':
                                results = results.sort((a, b) =>
                                    a.createdAt < b.createdAt ? -index : index
                                );
                                break;
                            default:
                                break;
                        }
                    });

                return {
                    data: results.slice(from, to),
                    total: results.length,
                    totalResults: totalResults,
                };
            }
        })
        .catch((error) => console.error(error));
}

export async function deleteFailedActivationLogs(id) {
    const config = {
        method: 'delete',
        url: `${API_URL}/failed-activation-logs`,
        withCredentials: true,
    };
    return await axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => console.error(error));
}