
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../api/auth';

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    async function logoutJWT() {
        const result = await logout();
        if (result.status === 200) {
          localStorage.setItem('isLoggedIn', 'false');
          navigate(`${process.env.PUBLIC_URL}/login`);
        }
    }
    logoutJWT();
    document.querySelector('body').classList.add('loaded');
  }, [navigate]);
  return null;
}
