import React, { useMemo } from 'react';

export default function TablePagination(props) {
  const { pages, page } = props;
  const elementsToShow = useMemo(() => {
    let elements = [];
    const displayPages = 7;

    if (pages <= displayPages) {
      for (let i = 0; i < pages; i++) {
        elements.push(i);
      }
      return elements;
    }
    if (page < displayPages - 1) {
      for (let i = 0; i < displayPages; i++) {
        elements.push(i);
      }
      elements.push(false); // Add dots
      elements.push(pages - 1); // Add the last page
    } else if (page - 1 >= pages - displayPages) {
      // Last pages
      elements.push(0); // Add the first page
      elements.push(false); // Add dots
      for (let i = pages - displayPages; i < pages; i++) {
        elements.push(i);
      }
    } else {
      // Middle pages
      elements.push(0); // Add the first page
      elements.push(false); // Add dots
      for (let i = page - Math.floor(displayPages / 2); i <= page + Math.floor(displayPages / 2); i++) {
        elements.push(i);
      }
      elements.push(false); // Add dots
      elements.push(pages - 1); // Add the last page
    }
    return elements;
  }, [page, pages]);

  function changePage(e, index) {
    e.preventDefault();
    props.onPageChange(index);
  }

  return (
    <>
      {pages > 1 ? (
        <ul
          className='pagination pagination-modern pagination-modern-spacing'
          role='navigation'
        >
          {page > 0 ? (
            <li className='page-item'>
              <a
                href='#prev'
                className='page-link'
                onClick={(e) => changePage(e, page - 1)}
              >
                <i className='fas fa-chevron-left'></i>
              </a>
            </li>
          ) : (
            <li className='page-item disabled'>
              <a className='page-link' href='#1'>
                <i className='fas fa-chevron-left'></i>
              </a>
            </li>
          )}
          {elementsToShow.map((item, index) => (
            <React.Fragment key={`page-item-${index}`}>
              {typeof item === 'number' ? (
                <li className={`page-item ${item === page ? 'active' : ''}`}>
                  <a
                    className='page-link'
                    href={`#page=${item}`}
                    onClick={(e) => changePage(e, item)}
                  >
                    {item + 1}
                  </a>
                </li>
              ) : (
                <li className='page-item disabled'>
                  <span className='page-link'>...</span>
                </li>
              )}
            </React.Fragment>
          ))}
          {page < pages - 1 ? (
            <li className='page-item'>
              <a
                href='#next'
                className='page-link'
                onClick={(e) => changePage(e, page + 1)}
              >
                <i className='fas fa-chevron-right'></i>
              </a>
            </li>
          ) : (
            <li className='page-item disabled'>
              <a className='page-link' href='#1'>
                <i className='fas fa-chevron-right'></i>
              </a>
            </li>
          )}
        </ul>
      ) : (
        ''
      )}
    </>
  );
}
