export function sortByAB(a, b, index, sort) {
    if (a === b) {
        return 0;
    }
    // nulls sort after anything else
    if (a === null) {
        return index;
    }

    if (b === null) {
        return -index;
    }
    // otherwise, if we're ascending, lowest sorts first
    if (!sort.desc) {
        return a > b ? -index : index;
    }
    return a < b ? index : -index;
}

export function hasMatchingValue(array1, array2) {
    if(Array.isArray(array1)){
        return array1.some(value => array2.includes(value));
    }else {
        return array2.includes(array1)
    }
}