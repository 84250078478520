import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getWhitelistLogs(page = 1, pageSize = 12, filters, sortBy) {
    const columnsToFilter = ['activationWhitelist.email', 'cost', 'createdAt'];
    const filtersQueryParam = filters ? `&filters=${encodeURIComponent(JSON.stringify({ filters, columnsToFilter }))}` : '';
    const sortByQueryParam = sortBy ? `&sortBy=${encodeURIComponent(JSON.stringify(sortBy))}` : '';

    const url = `${API_URL}/activation-logs?page=${page}&pageSize=${pageSize}${filtersQueryParam}${sortByQueryParam}`;

    try {
        const response = await axios.get(url, { withCredentials: true });
        return {
            data: response.data.message,
            total: response.data.total,
            totalResults: response.data.totalResults,
            totalCost: response.data.totalCost
        };
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch data');
    }
}