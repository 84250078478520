import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { sendResetToken } from '../../../api/auth';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { withDismissible } from '../../hoc';

export default function ForgotPassword() {
  const [emailSent, setEmailSent] = useState(false);
  const AlertDismissible = withDismissible(Alert);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.querySelector('body').classList.add('loaded');
  }, []);

  async function resetPassword(e) {
    e.preventDefault();
    const email = e.target.email.value;
    const result = await sendResetToken(email);
    if (result.status === 200 && result.data.error === 0) {
      setEmailSent(true);
    }
    setShow(true);
    setMessage(result.data.message);
  }

  return (
    <section className='body-sign'>
      <div className='center-sign'>
        {show && message && (
          <AlertDismissible variant='danger'>{message}</AlertDismissible>
        )}
        <Card className='card-sign'>
          <Card.Body>
            {!emailSent ? (
              <>
                <h2 className='sign-title'>Recover Password</h2>
                <Form onSubmit={resetPassword}>
                  <Form.Group className='form-custom-group mb-0'>
                    <InputGroup>
                      <Form.Control name='email' type='email' required placeholder='E-mail' />
                      <Button type='submit' variant='primary' disabled={emailSent}>
                        Reset!
                      </Button>
                    </InputGroup>
                  </Form.Group>

                  <p className='text-center mt-3'>
                    Remembered <Link to='/'>Sign In!</Link>
                  </p>
                </Form>
              </>
            ) : (
              <>
                <h2 className='sign-title'>Recover Password</h2>
                <div>
                  <p>Email has been sent. Please check your inbox.</p>
                  <p className='text-center mt-3'>
                    <Link to='/'>Sign In!</Link>
                  </p>
                </div>
              </>
            )}
          </Card.Body>
        </Card>

        <p className='text-center text-muted my-3'>
          &copy; Copyright 2021. All Rights Reserved.
        </p>
      </div>
    </section>
  );
}
