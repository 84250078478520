import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PUBLIC_URL || 'http://localhost:3002/api';

export async function getWidgetReplacementLogsWithFilter(from = 0, to, filters, sortBy) {
    const config = {
        method: 'get',
        url: `${API_URL}/widget-replacement-logs`,
        withCredentials: true,
    };
    return axios(config)
        .then((response) => {
            let error = response.data.error
            if (!error) {
                let results = response.data.message;
                let totalResults = response.data.message.length;
                filters &&
                    filters.forEach((filter) => {
                        results = results.filter((widgetReplacementLog) => {
                            const categoryValue = widgetReplacementLog?.category?.name || '-';
                            return (
                                widgetReplacementLog['key'].search(new RegExp(filter.value, 'i')) >= 0 ||
                                widgetReplacementLog['replacedBy'].search(new RegExp(filter.value, 'i')) >= 0 ||
                                categoryValue.search(new RegExp(filter.value, 'i')) >= 0 ||
                                widgetReplacementLog['createdAt'].search(new RegExp(filter.value, 'i')) >= 0
                            );
                        });
                    });

                sortBy &&
                    sortBy.forEach((sort) => {
                        let index = sort.desc ? -1 : 1;
                        switch (sort.id) {
                            case 'key':
                                results = results.sort((a, b) =>
                                    a.key < b.key ? -index : index
                                );
                                break;
                            case 'replacedBy':
                                results = results.sort((a, b) =>
                                    a.replacedBy < b.replacedBy ? -index : index
                                );
                                break;
                            case 'category':
                                results = results.sort((a, b) => {
                                    const aValue = a.category?.name || '-';
                                    const bValue = b.category?.name || '-';

                                    return aValue < bValue ? -index : index;
                                });
                                break;
                            case 'createdAt':
                                results = results.sort((a, b) =>
                                    a.createdAt < b.createdAt ? -index : index
                                );
                                break;
                            default:
                                break;
                        }
                    });

                return {
                    data: results.slice(from, to),
                    total: results.length,
                    totalResults: totalResults,
                };
            }
        })
        .catch((error) => console.error(error));
}

export async function getKeyReplacementWidgetLogsWithFilter(from = 0, to, filters, sortBy) {
    const config = {
        method: 'get',
        url: `${API_URL}/widget-replacement-logs/key-replacement`,
        withCredentials: true,
    };
    return axios(config)
        .then((response) => {
            let error = response.data.error
            if (!error) {
                let results = response.data.message;
                let totalResults = response.data.message.length;
                filters &&
                    filters.forEach((filter) => {
                        results = results.filter((widgetReplacementLog) => {
                            const categoryValue = widgetReplacementLog?.category?.name || '-';
                            return (
                                widgetReplacementLog['key'].search(new RegExp(filter.value, 'i')) >= 0 ||
                                widgetReplacementLog['replacedBy'].search(new RegExp(filter.value, 'i')) >= 0 ||
                                categoryValue.search(new RegExp(filter.value, 'i')) >= 0 ||
                                widgetReplacementLog['createdAt'].search(new RegExp(filter.value, 'i')) >= 0
                            );
                        });
                    });

                sortBy &&
                    sortBy.forEach((sort) => {
                        let index = sort.desc ? -1 : 1;
                        switch (sort.id) {
                            case 'key':
                                results = results.sort((a, b) =>
                                    a.key < b.key ? -index : index
                                );
                                break;
                            case 'replacedBy':
                                results = results.sort((a, b) =>
                                    a.replacedBy < b.replacedBy ? -index : index
                                );
                                break;
                            case 'category':
                                results = results.sort((a, b) => {
                                    const aValue = a.category?.name || '-';
                                    const bValue = b.category?.name || '-';

                                    return aValue < bValue ? -index : index;
                                });
                                break;
                            case 'createdAt':
                                results = results.sort((a, b) =>
                                    a.createdAt < b.createdAt ? -index : index
                                );
                                break;
                            default:
                                break;
                        }
                    });

                return {
                    data: results.slice(from, to),
                    total: results.length,
                    totalResults: totalResults,
                };
            }
        })
        .catch((error) => console.error(error));
}


export async function deleteKeyLog(id) {
    const config = {
        method: 'delete',
        url: `${API_URL}/widget-replacement-logs/${id}`,
        withCredentials: true,

    };
    return axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response;
        });
}