import Modal from "react-modal";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '600px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#app');

export default function ActivationAccountActivationsModal({ isOpen, onClose, currentAccount }) {

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Categorii asociate facturii
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg={12} xl={12}>
                            <Form.Group as={Row} className='align-items-center'>
                                <Col
                                    as={Form.Label}
                                    lg={3}
                                    xl={2}
                                    className='control-label text-lg-right mb-lg-0'
                                >
                                    # Activări
                                </Col>
                                <Col lg={9} xl={10}>
                                    <input
                                        type='text'
                                        name='numberOfActivations'
                                        id='inputNumberOfActivations'
                                        placeholder="# Activări"
                                        className='w-100 form-control-modern form-control'
                                        defaultValue={currentAccount.numberOfActivations ?? ''}
                                        onChange={(e) => currentAccount.numberOfActivations = e.target.value}
                                        required
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="danger"
                                onClick={() => onClose(false)}
                            >
                                Close
                            </Button>
                            <Button
                                className="modal-confirm mr-2"
                                variant="success"
                                onClick={() => onClose(currentAccount)}
                            >
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}