import React, { useRef, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import 'react-table/react-table.css';

import TablePagination from './table-pagination';

const CompleteTablePagination = (props) => {
  let paginationWrapper = document.querySelector('.pagination-wrapper');
  return paginationWrapper ? (
    ReactDOM.createPortal(<TablePagination {...props} />, paginationWrapper)
  ) : (
    <TablePagination {...props} />
  );
};

const SelectTable = selectTableHOC(ReactTable);

// Fix component PtSelectInput, from a component that is uncontrolled input to be controlled
class PtSelectInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: props.id, row: props.row, checked: props.checked };

    this.toggleState = this.toggleState.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleState = (e) => {
    e.stopPropagation();
    this.props.onClick(this.state.id, e.shiftKey, this.state.row);
  };

  onChange = (e) => {};

  render() {
    return (
      <input
        type='checkbox'
        id={this.state.id}
        className='checkbox-style-1 p-relative d-block'
        checked={this.state.checked}
        onClick={this.toggleState}
        onChange={this.onChange}
      />
    );
  }
}

export default function PtTable({ onChangeRef, type = 'select', ...props }) {
  const ref = useRef(null);
  const [resultsText, setResultsText] = useState([]);
  useEffect(() => {
    onChangeRef && onChangeRef(ref);
    const { isFiltered, totalFilteredRecords, totalRecords } = props || {};
    const total = isFiltered ? totalFilteredRecords : totalRecords || 0;
    
    const resultsText = total > 0
      ? `Total number of records: ${total}`
      : 'No records found';
    
    setResultsText(resultsText);
    
  }, [onChangeRef,props, resultsText]);
  if (type === 'select') {
    return (
    <>
      <SelectTable
        ref={ref}
        keyField='id'
        sortable={false}
        multiSort={false}
        selectWidth={40}
        {...props}
        PaginationComponent={CompleteTablePagination}
        SelectAllInputComponent={PtSelectInput}
        SelectInputComponent={PtSelectInput}
      />{resultsText ?
      (<Row className='align-items-center justify-content-between mt-3'>
        <Col lg='auto' className='mb-3 mb-lg-0'>
            <div className='d-flex justify-content-lg-end'>{resultsText}</div>
        </Col>
      </Row>)
       : ('')}
    </>
    );
  } else {
    return (
      <ReactTable
        ref={ref}
        sortable={false}
        multiSort={false}
        {...props}
        PaginationComponent={TablePagination}
      />
    );
  }
}
