import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../common/breadcrumb';
import { toast } from 'react-toastify';
import PNotify from '../../../features/elements/p-notify';
import { createWhitelist } from '../../../api/whitelist';


export default function WhitelistCreate() {
    const navigate = useNavigate();

    async function saveWhitelist(e) {
        e.preventDefault();

        let data = {
            email: e.target.email.value,
            remainingActivations: e.target.remainingActivations.value,
        };
        const result = await createWhitelist(data);
        if (result.status === 200 && result.data.error === 0) {
            document.getElementById('myForm').reset();
            return toast(
                <PNotify
                    title='Success'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-success',
                }
            );
        } else {
            return toast(
                <PNotify
                    title='Error'
                    icon='fas fa-check'
                    text={result.data.message}
                />,
                {
                    containerId: 'default',
                    className: 'notification-danger',
                }
            );
        }
    }

    useEffect(() => {
        document.querySelector('body').classList.add('loaded');
    });

    return (
        <>
            <Breadcrumb
                current={'Adaugă cont activare'}
                paths={[
                    {
                        name: 'Dashboard',
                        url: '/dashboard',
                    },
                    {
                        name: 'PANOU PENTRU ÎNTREȚINERE CEL',
                        url: '#',
                    },
                    {
                        name: 'Conturi Activare',
                        url: '/dashboard/whitelist',
                    },
                    {
                        name: 'Adaugă cont activare',
                        url: '/dashboard/whitelist/create',
                    },
                ]}
            />

            <Form
                className='core-form form'
                action='#'
                method='post'
                onSubmit={saveWhitelist}
                id='myForm'
            >
                <Row>
                    <Col>
                        <Card className='card-modern card-big-info'>
                            <Card.Body>
                                <Row>
                                    <Col lg='2-5' xl='1-5'>
                                        <i className='card-big-info-icon bx bxs-user-badge'></i>
                                        <h2 className='card-big-info-title'>Info cont activare</h2>
                                    </Col>
                                    <Col lg='3-5' xl='4-5' className='m-auto'>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                Email
                                            </Col>
                                            <Col lg={7} xl={6}>
                                                <Form.Control
                                                    type='email'
                                                    maxLength='64'
                                                    className='form-control-modern'
                                                    name='email'
                                                    required
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className='align-items-center'>
                                            <Col
                                                as={Form.Label}
                                                lg={5}
                                                xl={3}
                                                className='control-label text-lg-right mb-lg-0'
                                            >
                                                # Activări rămase
                                            </Col>
                                            <Col lg={7} xl={6}>
                                                <Form.Control
                                                    type='number'
                                                    maxLength='64'
                                                    className='form-control-modern'
                                                    name='remainingActivations'
                                                    required
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='action-buttons'>
                    <Col md='auto' className='col-12'>
                        <Button
                            type='submit'
                            className='btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1'
                            variant='primary'
                        >
                            <i className='bx bx-save text-4 mr-2'></i>Salvează cont activare
                        </Button>
                    </Col>
                    <Col md='auto' className='col-12 px-md-0 mt-3 mt-md-0'>
                        <Button
                            onClick={() => navigate(-1)}
                            className="btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center"
                            variant="light"
                        >
                            Înapoi
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}