import Modal from "react-modal";
import { Row, Col, Card, Button } from 'react-bootstrap';

const modalStyles = {
    overlay: {
        background: 'rgba(11, 11, 11, .8)',
        zIndex: 99999
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        right: 'auto',
        outline: 'none',
        width: '600px',
        maxWidth: '100%',
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

Modal.setAppElement('#app');

export default function InvoiceAssociationModal({ isOpen, onClose, invoiceData }) {
    // Check if there is invoice data or if the keyStorage array is empty
    const hasData = invoiceData && invoiceData.keyStorage && invoiceData.keyStorage.length > 0;
    // Process the keyStorage data to get the count of keys per category
    const categoryKeyCount = hasData
        ? invoiceData.keyStorage.reduce((acc, key) => {
            const categoryName = key.catLicenseCatModule ? key.catLicenseCatModule.name : 'Unknown Category';
            if (!acc[categoryName]) {
                acc[categoryName] = 0;
            }
            acc[categoryName]++;
            return acc;
        }, {})
        : null;

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyles}>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Categorii asociate facturii
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {categoryKeyCount ? Object.entries(categoryKeyCount).map(([categoryName, count]) => (
                        <strong key={categoryName}>
                            <p key={categoryName}>
                                - {categoryName}: {count}
                            </p>
                        </strong>
                    )) : <strong>Nu exista chei asociate facturii.</strong>}
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col md={12} className="text-right">
                            <Button
                                className="modal-confirm mr-2"
                                variant="primary"
                                onClick={() => onClose(false)}
                            >
                                Ok
                            </Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Modal>
    )
}