import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../common/breadcrumb';
import PtTable from '../../../features/elements/table';
import PNotify from '../../../features/elements/p-notify';
import { getActivityLogsWithFilter } from '../../../api/activity-logs';
import LoggedDetailsModal from '../../../features/modals/logged-details-modal';

export default function ActivityLogsList() {
  const [loading, setLoading] = useState(true);
  const [ajax, setAjax] = useState({
    data: [],
    total: 0,
  });

  // Filter Variables
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [tableRef, setTableRef] = useState(null);
  const [selected, setSelected] = useState([]);
  const [bulk, setBulk] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [details, setDetails] = useState('');

  // Columns
  const columns = [
    {
      Header: 'Actiune',
      accessor: 'eventType',
      sortable: true,
    },
    {
      Header: 'Ruta',
      accessor: 'route',
      sortable: true,
    },
    {
      Header: 'Utilizator',
      accessor: 'email',
      sortable: true,
    },
    {
      Header: 'Rol',
      accessor: 'role',
      sortable: true,
    },
    {
      Header: 'Data',
      accessor: 'createdAt',
      sortable: true,
    },
    {
      Header: 'Statut',
      accessor: 'status',
      sortable: true,
      Cell: (row) => (
        <>
         {row.value === false ? (
          <div className='alert alert-success'>Success</div>
         ) : (
          <div className='alert alert-danger'>Failed</div>
         )
         }
        </>
      )
    },
    {
      Header: 'Modificare',
      accessor: 'modification',
      sortable: true,
      Cell: (row) => (
        <>
          <Button className='on-default edit-row mr-2' onClick={() => openDetailsModal(row.value)}>
            <i className='fas fa-eye'></i> Vizualizeaza
          </Button>
        </>
      ),
    }
  ];

  function openDetailsModal(modification){
    setDetails(modification);
    setOpenModal(true);
  }
  useEffect(() => {
    setSelected((selected) =>
      selected.map((item) => {
        return {
          ...item,
          selected: selectAll,
        };
      })
    );
    document.querySelector('body').classList.add('loaded');
  }, [selectAll]);

  function isSelected(key) {
    return selected.find((item) => item.id === key && item.selected);
  }

  function onSelectChange(e, value, row) {
    setSelected(
      selected.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      })
    );
  }

  async function bulkAction(e) {
    e.preventDefault();
    if (!bulk) {
      return toast(
        <PNotify
          title='Warning'
          icon='fas fa-exclamation'
          text='Please choose one of actions.'
        />,
        {
          containerId: 'default',
          className: 'notification-warning',
        }
      );
    }
  }

  function searchActivityLogs(e) {
    e.preventDefault();
    tableRef.current.wrappedInstance.filterColumn({ id: 'search' }, search);
  }

  function fetchData(state) {
    let filtered = [...state.filtered];
    setLoading(true);
    getActivityLogsWithFilter(
      state.page * state.pageSize,
      (state.page + 1) * state.pageSize,
      filtered,
      state.sorted
    ).then((results) => {
      if(results?.data){
        setAjax({
          data: results.data,
          total:
            parseInt(results.total / state.pageSize) +
            !!(results.total % state.pageSize),
          totalRecords: results.totalResults,
          totalFilteredRecords: results.total,
          isFiltered: filtered.length ? true : false,
        });
        setSelected(
          results.data.map((media) => {
            return {
              id: media.id,
              selected: false,
            };
          })
        );
        setSelectAll(false);
      }
      setLoading(false);
    });
  }

  return (
    <>
      <Breadcrumb
        current={'Activity Logs'}
        paths={[
          {
              name: 'Dashboard',
              url: '/dashboard',
          },
          {
              name: 'Settings',
              url: '#',
          },
          {
              name: 'Activity Logs',
              url: '#',
          },
          {
              name: 'Listează Activity Logs',
              url: '/dashboard/activity-logs',
          },
      ]}
      />

      <Row>
        <Col>
          <Form method='GET' action='#' onSubmit={searchActivityLogs}>
            <Card className='card-modern'>
              <Card.Body>
                <div className='datatables-header-footer-wrapper'>
                  <div className='datatable-header'>
                    <Row className='align-items-lg-center mb-3'>
                      <Col
                        lg='auto'
                        className='mb-2 mb-lg-0 ml-xl-auto pl-xl-1'
                      ></Col>
                      <Col className='col-auto pl-lg-1'>
                        <div className='search search-style-1 mx-lg-auto w-auto'>
                          <InputGroup>
                            <Form.Control
                              type='text'
                              className='search-term'
                              placeholder='Search'
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <InputGroup.Append>
                              <Button variant='default' type='submit'>
                                <i className='bx bx-search'></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <PtTable
                    className='table table-core-simple -striped mb-0'
                    data={ajax.data}
                    loading={loading}
                    columns={columns}
                    pages={ajax.total}
                    pageSize={10}
                    manual
                    onFetchData={fetchData}
                    selectAll={selectAll}
                    toggleAll={() => setSelectAll(!selectAll)}
                    isSelected={(key) => isSelected(key)}
                    toggleSelection={onSelectChange}
                    onChangeRef={(ref) => setTableRef(ref)}
                    totalRecords={ajax.totalRecords}
                    totalFilteredRecords={ajax.totalFilteredRecords}
                    isFiltered={ajax.isFiltered}
                  />

                  <div className='datatable-footer'>
                    <Row className='align-items-center justify-content-between mt-3'>
                      <Col md='auto' className='mb-3 mb-lg-0'>
                        <div className='d-flex'>
                          <Form.Control
                            as='select'
                            className='select-style-1 bulk-action w-auto mr-3'
                            value={bulk}
                            onChange={(e) => setBulk(e.target.value)}
                            style={{ minWidth: '120px' }}
                          >
                            <option value=''>Bulk Actions</option>
                          </Form.Control>
                          <Button
                            href='#bulk-action'
                            className='bulk-action-apply border font-weight-semibold text-color-dark text-3'
                            variant='light'
                            onClick={bulkAction}
                          >
                            Apply
                          </Button>
                        </div>
                      </Col>
                      <Col lg='auto' className='mb-3 mb-lg-0'>
                        <div className='pagination-wrapper d-flex justify-content-lg-end'></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      </Row>

      <LoggedDetailsModal 
          isOpen={openModal} 
          onClose={() => setOpenModal(false)}
          details={details}
      />
    </>
  );
}
